
import React, { useState } from "react";
import ImageCardSelection from '../../components/ImageCardSelection/ImageCardSelection';
import TitleField from '../../components/TitleField/TitleField';
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useStyles} from './ComoTrabajamosStyles';
import data from './data';
import NavBar from "../../components/Navbar.js";
import Contacto from "../../components/Contacto.js";
import './ComoTrabajamos.css';
import './../../AppStyles.css';
import HiddenTextImage from "../../components/HiddenTextImage/HiddenTextImage";


const ComoTrabajamosScreen = (props) => {
    //---------------------------------------------------------------------------
    // Text Constants
    //---------------------------------------------------------------------------
    const TitleText = (<p>Ofrecemos experiencias de inmersión comunitaria <br/> en diferentes países. En un viaje de voluntariado, <br/> te invitamos a visitar comunidades a las que <br/> normalmente no llegarías como turista. </p>);
    const BottomText = (<p>Brindamos consultoría, conferencias y talleres <br/> en innovación social y desarrollo comunitario; <br/> somos expertos en realizar diagnósticos <br/> comunitarios y aplicar la metodología design <br/> thinking enfocada en problemáticas sociales. </p>);
    const isMobile = useMediaQuery("(max-width:760px)");

    //---------------------------------------------------------------------------
    // useState Hook
    //---------------------------------------------------------------------------
    const [tiposVoluntariado] = useState(data?.properties || [],);
    const [comoTrabajamosData] = useState(data?.comoTrabajamos || [],);

    //---------------------------------------------------------------------------
    // Functions
    //---------------------------------------------------------------------------

    return <div className="ComoTrabajamos">
                <NavBar isMobile={isMobile} showCovid={true} fillColor={"#1DCAD3"} isHome={true} />
                <TitleField text={TitleText} numeration={"01"}/> 
                <ImageCardSelection 
                    properties={tiposVoluntariado} 
                /> 

                <Grid
                className={useStyles.root} 
                container
                justify="center"
                >
                    <Grid item >
                        <button 
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href='http://bit.ly/Viajes2021TDA';
                                }}
                            className="WideButton PrimaryColor Informacion">Hacer un voluntariado</button>
                    </Grid>
                    <Grid item xs={12}>
                        <TitleField text={BottomText}  numeration={"02"}/> 
                    </Grid>
                    <Grid item xs={12}>
                        <div className="containerHiddenTextImage">
                            <HiddenTextImage
                                properties={comoTrabajamosData} 
                            /> 
                        </div>
                    </Grid>
                    <Grid >
                        <button 
                            onClick={(e) => {
                                    window.location = "mailto:dabella@tierradeartistas.org";
                                    e.preventDefault();
                                }}
                            className="WideButton SecondaryColor Informacion">Trabajemos juntos</button>
                    </Grid>
                    <Grid 
                        item xs={12}
                        container
                        id="contactoBlock"
                        className="panel">
                        <Contacto isMobile={isMobile}/>
                    </Grid>
                </Grid>
                
            </div>;
};

export default ComoTrabajamosScreen;
