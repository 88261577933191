import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogDiv, InnerCard, CloseButton, TitleDiv, TitleText, RequirementsContainer } from './ModalRequisitosStyles';
import RequirementCard from './RequirementCard/RequirementCard';
import identificacion from './../../images/iconos/IdentificacionOficial.png';
import mayorDeEdad from './../../images/iconos/MayorDeEdad.png';
import serAceptado from './../../images/iconos/SerAceptado.png';


const requisitos = [
    {
        url: mayorDeEdad,
        text: 'Ser mayor de edad.',
    },
    {
        url: identificacion,
        text: 'Tener tus documentos en orden (identificación oficial).',
    },
    {
        url: serAceptado,
        text: 'Aplicar y ser aceptado.',
    }
]

const ModalRequisitos = (props) => {
    const { open, setOpen } = props;
    return <Dialog maxWidth={false} open={open} PaperComponent={DialogDiv} onClose={() => setOpen(false)}>
        <InnerCard>
            <TitleDiv>
                <TitleText>Requisitos para separar tu lugar</TitleText>
            </TitleDiv>
            <RequirementsContainer>
                {requisitos.map((req, reqIndex) => {
                    return <RequirementCard {...req} key={`${req.text}_${reqIndex}`} />
                })}
            </RequirementsContainer>
        </InnerCard>
        <CloseButton onClick={() => { setOpen(false) }} />
    </Dialog>
};

export default ModalRequisitos;