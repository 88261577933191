import React from 'react';
import './HiddenTextImage.css';
import Grid from "@material-ui/core/Grid";
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ButtonNext from '../../images/iconos/botonSiguienteActivoBlanco.png';
import ButtonBack from '../../images/iconos/botonAtrasActivoBlanco.png';

// class component
function ImageCardSelection (props) {
    const {properties} = props;
    
    //---------------------------------------------------------------------------
    // useState Hook
    //---------------------------------------------------------------------------
    const [firstTextOpen, setFirstTextOpen] = useState(false);
    const [secondTextOpen, setSecondTextOpen] = useState(false);
    
    //---------------------------------------------------------------------------
    // functions
    //---------------------------------------------------------------------------

        return (
        <div className="ImageHidden">
            <Grid
                container
                alignContent="center"
                justify="center"
                direction="column"
                alignItems="center"
                spacing={3}
            >
                <Grid
                    item
                    xs={12}
                >
                    <div className="Container">
                        <div 
                            className={ firstTextOpen ? "ItemTextOpenRight Informacion" : "ItemTextRight"} 
                            onClick={firstTextOpen ? () => setFirstTextOpen(false) : () => setFirstTextOpen(true)}
                        >
                            {firstTextOpen && <div className="TextOpen"> {properties ? properties[0].text : ""} </div>}
                        </div>
                        <div className={ firstTextOpen ? "ItemIconOpenRight" : "ItemIconRight"}>
                            {!firstTextOpen && <img
                                                    src={ButtonBack}
                                                    className="ButtonUpHiddenImage BigArrowButton"
                                                    onClick={() => setFirstTextOpen(true)} 
                                                    alt="Back Button"
                                                />
                            }
                            {firstTextOpen && <img
                                                    src={ButtonNext}
                                                    className="ButtonUpHiddenImage BigArrowButton"
                                                    onClick={() => setFirstTextOpen(false)}
                                                    alt="Back Button"
                                                />
                            }
                        </div>
                        <div className={firstTextOpen ? "ItemTitleOpenRight Titulos" : "ItemTitleRight Titulos"}>
                            {properties ? properties[0].title : ""}
                        </div>
                        <img className="HiddenImage"
                            src={properties ? firstTextOpen ? properties[0].closed : properties[0].open : ""} 
                            alt={properties ? properties[0].title : ""}/>
                    </div>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <div className="Container">
                        <div 
                            className={ secondTextOpen ? "ItemTextOpenLeft Informacion" : "ItemTextLeft"} 
                            onClick={secondTextOpen ? () => setSecondTextOpen(false) : () => setSecondTextOpen(true)}
                        >
                            {secondTextOpen && <div className="TextOpen"> {properties ? properties[1].text : ""} </div>}
                        </div>
                        <div className={ secondTextOpen ? "ItemIconOpenLeft" : "ItemIconLeft"}>
                            {!secondTextOpen && <img
                                                    src={ButtonNext}
                                                    className="ButtonDownHiddenImage BigArrowButton"
                                                    onClick={() => setSecondTextOpen(true)} 
                                                    alt="Back Button"
                                                />
                            }
                            {secondTextOpen && <img
                                                    src={ButtonBack}
                                                    className="ButtonDownHiddenImage BigArrowButton"
                                                    onClick={() => setSecondTextOpen(false)}
                                                    alt="Back Button"
                                                />
                            }
                        </div>
                        <div className={secondTextOpen ? "ItemTitleOpenLeft Titulos" : "ItemTitleLeft Titulos"}>
                            {properties ? properties[1].title : ""}
                        </div>
                        <img className="HiddenImage"
                            src={properties ? secondTextOpen ? properties[1].closed : properties[1].open : ""} 
                            alt={properties ? properties[1].title : ""}/>
                    </div>
                </Grid>
            </Grid>
            
                                
        </div>
        );
}

export default ImageCardSelection;
