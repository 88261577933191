import React from 'react';
import PropTypes from 'prop-types';
import './ImageIconCard.css';
import '../../../Screens/CaminoRecorrido/CaminoRecorrido.css';
import '../../../AppStyles.css';

const ImageCard = ({property}) => {
    const {index, picture, text, date, icon, iconPosition} = property;
    let classesCard = ["card"];
    let classesIcon = ["iconSlider"];

    switch(iconPosition){
        case "left":
            classesIcon.push("iconleft");
            break;
        case "right":
            classesIcon.push("iconRight");
            break;
        case "center":
            classesIcon.push("iconCenter");
            break;
        default:
            break;
    }

    return (
        <div id={`card-${index}`} className={classesCard.join(" ")}>
            <div className="container">
                <img className="Image" src={picture} alt={date} />
                <img className={classesIcon.join(" ")} src={icon} alt={date} />
            </div>
            <div className="details Details-Card">
                <p className="TextoCorrido">{text}</p>
            </div>
        </div>
    )
}

ImageCard.propTypes = {
    property: PropTypes.object.isRequired
}

export default ImageCard;