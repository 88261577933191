import React from "react";
import { Grid } from "@material-ui/core";
import ModalCupoLleno from "./ModalCupoLleno/ModalCupoLleno.js";
import ModalEnConstruccion from "./ModalEnConstruccion/ModalEnConstruccion.js";

const ProximoViaje = (props) => {
  const {
    imgSrc,
    title,
    subtitle,
    soldOut,
    description,
    descriptionTitle,
  } = props;

  const [isOpen, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [construccionModal, setConstruccionModal] = React.useState(false);
  
  return (
    <Grid item xs={12} sm={6} md={3} className={`proximoViajeContent`}>
      <img
        src={imgSrc}
        className="proximoViajeCardImage"
        alt={title + " " + subtitle}
        style={{ marginBottom: isOpen ? "-46%" : "0%" }}
        onClick={() => setOpen((opened) => !opened)}
      />
      <div
        id="cardDeslizable"
        style={{
          backgroundColor: soldOut ? "#f0b500" : "#F9F7F1",
          minHeight: isOpen ? "50%" : "",
          height: isOpen ? 'auto' : "30%",
        }}
        onClick={() => setOpen((opened) => !opened)}
      >
        <h2
          className="proximoViajeCardTitle"
          style={{
            backgroundColor: soldOut ? "#f0b500" : "#F9F7F1",
            color: soldOut ? "#FFF" : "#1DCAD3",
            marginTop: isOpen ? "0px" : "10px",
            marginBottom: isOpen ? "0px" : "10px",
          }}
        >
          {title}
        </h2>
        <h4
          className="proximoViajeCardSubtitle"
          style={{
            backgroundColor: soldOut ? "#f0b500" : "#F9F7F1",
            color: soldOut ? "#FFF" : "#f0b500",
          }}
        >
          {subtitle}
        </h4>

        {isOpen ? (
          <>
            <p
              className="detailsTitle"
              style={{
                backgroundColor: soldOut ? "#f0b500" : "#F9F7F1",
                color: soldOut ? "#FFF" : "#000",
              }}
            >
              {descriptionTitle}
            </p>
            <p
              className="proximoViajeDescription"
              style={{
                backgroundColor: soldOut ? "#f0b500" : "#F9F7F1",
                color: soldOut ? "#FFF" : "#000",
              }}
            >
              {description}
            </p>
            {soldOut ? (
              <p
                // href="www.google.com"
                onClick={() => setModalOpen(true)}
                className="proximoViajeLink"
                style={{
                  backgroundColor: "#f0b500",
                  color: "#FFF",
                }}
              >
                CUPO LLENO
              </p>
            ) : (
              <a
                href="https://bit.ly/aplicatravelteer"
                target="_blank"
                className="proximoViajeLink"
                style={{
                  backgroundColor: "#F9F7F1",
                  color: "#1DCAD3",
                }}
              >
                Me interesa
              </a>
            )}
          </>
        ) : null}
      </div>
      <ModalCupoLleno open={modalOpen} setOpen={setModalOpen} />
      <ModalEnConstruccion
        open={construccionModal}
        setOpen={setConstruccionModal}
      />
    </Grid>
  );
};

export default ProximoViaje;
