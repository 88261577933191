import React, { Component } from 'react';
import './ImageIconSlider.css';
import Card from './ImageIconCard/ImageIconCard';

// class component
class ImageSlider extends Component {

    constructor(props){
        super(props);
        this.state = {
            initialClientX: 0,
            finalClientX: 0,
            dragging: false,
        }
    }

    handleTouchStart = (event) => {
        this.setState({
            initialClientX: event.nativeEvent.touches[0].clientX
        });
    }

    handleTouchMove = (event) => {
        this.setState({
            finalClientX: event.nativeEvent.touches[0].clientX
        });
    }

    handleTouchEnd = () => {
        if ((this.state.finalClientX - this.state.initialClientX) > 100) {
            this.props.prevIndex();
        }else if ((this.state.finalClientX - this.state.initialClientX) < -100){
            this.props.nextIndex();
        }
    
        this.setState({
            initialClientX: 0,
            finalClientX: 0,
        });
    }

    handleMouseStart = (event) => {
        if (event.button !== 0) return

        this.setState({
            dragging: true,
            initialClientX: event.screenX,
        });
    }

    handleMouseMove = (event) => {
        if(!this.state.dragging) return;

        this.setState({
            finalClientX: event.screenX,
        });
    }

    handleMouseEnd = () => {
        if(!this.state.dragging) return;

        if ((this.state.finalClientX - this.state.initialClientX) > 10) {
            this.props.prevIndex();
        }else if ((this.state.finalClientX - this.state.initialClientX) < -10){
            this.props.nextIndex();
        }
    
        this.setState({
            dragging: false,
            initialClientX: 0,
            finalClientX: 0,
        });
    }

    render() {
        const {properties, currIndex, isMobile = false} = this.props;
        const property = properties[currIndex];

        let slideTransitions = 3;

        if(isMobile)
            slideTransitions = 1;

        return (
        <div>
            <div className="pageIconSlider" 
                onTouchMove={this.handleTouchMove} onTouchStart={this.handleTouchStart} onTouchEnd={this.handleTouchEnd}
                onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseStart} onMouseUp={this.handleMouseEnd}>
                <div className={`cards-slider active-slide-${property?.index}`}>
                    <div className="cards-slider-wrapperIcon" style={{
                    'transform': `translateX(-${(property?.index )*(slideTransitions * 100/properties.length)}%)`
                    }}>
                    {
                        properties.map(property => <Card key={property._id} property={property} />)
                    }
                    </div>
                </div>

            </div>
        </div>
        );
    }
}

export default ImageSlider;
