import styled from 'styled-components';




export const MainCardContainer = styled.div`
    display:flex;
    flex-direction:row;
    width: 100%;
    margin-right: 20%;
    @media (max-width: 760px) {
        margin: 10px;
    }
`

export const ImageContainer = styled.div`
    width: 80px;
    height: 80px;
    background-image: url(${props => props.imageUrl});
    @media (max-width: 760px) {
        margin: auto;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex:1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 40px;
    @media (max-width: 760px) {
        margin: auto;
    }
`;

export const RequirementText = styled.p`
    font-size: 20pt;
    font-family: 'Work Sans', sans-serif;
    margin:0;
    flex: 1;
    @media (max-width: 760px) {
        margin: auto;
        margin-left: 20px;
        font-size: 15px;
    }
`