import styled from "styled-components";

export const VivenciasContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;

  /* VIVENCIAS INICIO */
  #vivencias2Container{
    display: flex;
    flex-direction:column;
    width: 100%;
    justify-content:center;
    text-align: left;
  }
  .vivenciasHorizontalContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 5px;
    margin-bottom: 5px;
    text-align: left;
    flex:auto;
  }

  .vivenciasHorizontalContainer2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 5px;
    text-align: left;
    flex:1;
  }

  .vivenciasVerticalContainer {
    display: flex;
    flex-direction: column;
    height: inherit;
    width: 100%;
    margin-top: 5px;
    text-align: left;
    margin-left: 10px;
  }

  .vivenciasFullHeightImage {
    display: inline-block;
    width: 55%;
    height: auto;
    max-height: inherit ;
    margin-right: 15px;
    min-height: 265px;
  }

  .vivenciasFullHeightImage2 {
    display: inline-block;
    width: 52%;
    height: auto;
    margin-left: 15px;
    min-height: 350px;
  }

  .vivenciasFullWidthImage {
    display: block;
    width: 100%;
  }

  .descriptionFullHeightContainer {
    display: inline-block;
    height: auto;
    flex: 1;
    word-wrap: break-word;
    background-color: #f9f7f1;
  }

  .descriptionFullWidthContainer {
    display: block;
    max-width: 100%;
    height: auto;
    /* overflow-y: scroll; */
    background-color: #f9f7f1;
    margin-top: 15px;
    flex: auto;
  }

  .vivenciasCardTitle {
    color: #1DCAD3;
    /* font-size: 18px; PROPUESTA */
    font-size:16px;
    font-weight: 700;
    margin: 0px;
    padding: 25px 25px 0px 25px;
  }
  .vivenciasCardSubtitle {
    color: #373434;
    /* font-size: 19px; PROPUESTA */
    font-size:17px;
    font-weight: 900;
    margin-top: 0px;
    margin-bottom: 10px;
    padding: 0px 25px 0px 25px;
  }
  .vivenciasCardDescription {
    color: #373434;
    /* font-size: 17px; PROPUESTA */
    font-size:19px;
    font-weight: 400;
    margin: 0px;
    padding: 0px 25px 25px 25px;
    line-height: 1;
  }
  #secondRow {
    text-align: right;
  }

  @media  (max-width: 1280px) {
    .vivenciasFullHeightImage {  
      min-height: 240px;
    }
  }

  @media  (min-width: 1600px) {
    .vivenciasFullHeightImage2 {  
      width:60%
    }
    .vivenciasFullHeightImage {  
      width:50%
    }
  }

  @media (min-width: 1030px) and (max-width: 1600px) {
    .vivenciasCardTitle {
      font-size: 15px;
    }
    .vivenciasCardSubtitle {
      font-size: 15px;
    }
    .vivenciasCardDescription {
      font-size: 16px;
    }
  }
 
  @media (min-width: 760px) and (max-width: 1030px) {
    .vivenciasCardTitle {
      font-size: 12px;
    }
    .vivenciasCardSubtitle {
      font-size: 13px;
    }
    .vivenciasCardDescription {
      font-size: 14px;
    }
    .vivenciasFullHeightImage2 {  
      width:55%
    }
    .vivenciasFullHeightImage {  
      width:50%
    }
  }

  @media (max-width: 760px){
    display: flex;
    max-width: 100vw;
    overflow-x: scroll;
    justify-content: flex-start;
    flex-wrap: nowrap;

    .responsiveCardContainer{
      min-width: 80vw;
      padding:10px;
      display: inline-block;
      text-align:center;
    }
    .responsiveImage {
      max-width: 100%;
      width:auto;
      height: auto;
      max-height:50vh;
      overflow-x: hidden;
      overflow-y: hidden;
    }
    .responsiveDescriptionContainer {
      height: auto;
      width: 90%;
      margin: auto;
      margin-top:10px;
      background-color: #f9f7f1;
      padding: 15px;
    }
    .responsiveCardTitle {
      color: #1DCAD3;
      font-size: 10px;
      font-weight: 700;
      margin: 0px;
    }
    .responsiveCardSubtitle {
      color: #373434;
      font-size: 14px;
      font-weight: 900;
      margin-top: 0px;
    }
    .responsiveCardDescription {
      color: #373434;
      font-size: 14px;
      font-weight: 400;
      margin: auto;
      width:90%;
      text-align: justify;
    }
  }
  /* VIVENCIAS FIN */
`;
