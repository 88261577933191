
import React, { useState } from "react";
import TitleField from '../../components/TitleField/TitleField';
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useStyles} from './NuestroEquipoStyles';
import {dataEquipo, dataCoordinadores, ComillasAbiertas, ComillasCierre } from './data';
import NavBar from "../../components/Navbar.js";
import Contacto from "../../components/Contacto.js";
import './../../AppStyles.css';
import './NuestroEquipo.css';

import ButtonNext from '../../images/iconos/botonSiguienteActivo.png';
import ButtonBack from '../../images/iconos/botonAtrasActivo.png';
import ButtonNextDisabled from '../../images/iconos/siguienteDisabled.png';
import ButtonBackDisabled from '../../images/iconos/atrasDisabled.png';

const ImageWithHiddenText = (props) => {
    const [mouseOverCard, setMouseOverCard] = useState(false);
    let classesCard = ["ImageWithHiddenText"];

    if(props.cardPos === "down" && props.isMobile !== true)
        classesCard.push("cardDown");

    return <div 
                className={classesCard.join(" ")} 
                onMouseEnter={() => setMouseOverCard(true)}
                onMouseLeave={() => setMouseOverCard(false)}>
        <div className="imageContainer">
            <img src={ mouseOverCard ? props.imageFiltro : props.image} alt="hozImg" />
        </div>
        {!mouseOverCard && <div className="titleContainer Titulos ThemeColor StandardBoldText">
                {props.name}
            </div>
        }
        {mouseOverCard &&  <div className="titleContainerTop Titulos ThemeColor StandardBoldText">
                {props.name}
            </div> 
        }
        {mouseOverCard &&  <div className="AlternativeColor textContainer TextoCorrido "> 
            <div className="StandardBoldText generalText">{props.text}</div>
            <p>-</p>
            <div className="StandardBoldText generalText blackLittle">{props.position}</div>
        </div>
        }
    </div>
}

const ImageWithText = (props) => {
    let containerClass = ["imageWithText"];
    if(props.cardPos === "down" || props.isMobile === true)
        containerClass.push("imgDownSmall")
    
    return <div className={containerClass.join(" ")}>
        <div className="imageContainerHorizontal">
            <img src={props.image} alt="hozImg" />
        </div>
        <div className="textContainerHorizontal">
            <div className="Informacion StandardBoldText SecondaryColor smallContainerSpacing">{props.name}</div>
            <div className="TextoCorrido MediumBoldText SecondaryColor smallContainerSpacing">"{props.text}"</div>
        </div>
    </div>
}

const NuestroEquipoScreen = (props) => {
    //---------------------------------------------------------------------------
    // Text Constants
    //---------------------------------------------------------------------------
    const TitleText = (<p> Somos una gran familia, <br/> formada por un equipo <br/> proactivo, voluntarios creativos <br/> y comunidades vulnerables. </p>);
    const isMobile = useMediaQuery("(max-width:760px)");

    //---------------------------------------------------------------------------
    // useState Hook
    //---------------------------------------------------------------------------
    const [selectedCoordIndex, setSelectedCoordIndex] = useState(0);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
    const [backButtonDisabled, setBackButtonDisabled] = useState(true);

    //---------------------------------------------------------------------------
    // Functions
    //---------------------------------------------------------------------------
    const UpdateButtonStatus = (newIndex) => {
        if(EvaluateIndex(newIndex)){
            setSelectedCoordIndex(newIndex);
            DisableButtons(newIndex);
        }   
    }

    const DisableButtons = (index) => {
        if(index === 0)
            setBackButtonDisabled(true);
        else
            setBackButtonDisabled(false);
        
        if(index === (dataCoordinadores.length -1))
            setNextButtonDisabled(true);
        else
            setNextButtonDisabled(false);
    }

    const EvaluateIndex = (index) => {
        return (index >= 0  && index < dataCoordinadores.length);
    }

    return <div className="NuestroEquipo">
                <NavBar isMobile={isMobile} showCovid={true} fillColor={"#1DCAD3"} isHome={true} />
                
                <div className="equipoContainer">
                    <Grid
                    container
                    direction="row"
                    justify="center"
                    spacing={10}
                    >
                        {dataEquipo.map(element => {
                            return <Grid item sx={12} sm={4} key={element.id}>
                                <ImageWithHiddenText 
                                    isMobile={isMobile}
                                    image={element.picture} 
                                    imageFiltro={element.pictureFiltro}
                                    name={element.name}
                                    text={element.text}
                                    position={element.position}
                                    cardPos={element.cardPos}/>
                            </Grid>
                        })}
                    </Grid>
                </div>

                <div className="GiantTextDivision">
                    <div className="openImage">
                        <img src={ComillasAbiertas} alt="hozImg" />
                    </div>
                    <div className="giantText TitulosGrande SecondaryColor StandardBoldText">
                        {TitleText}
                    </div>
                    <div className="closeImage">
                        <img src={ComillasCierre} alt="hozImg" />
                    </div>
                </div>

                <div className="coordContainer">
                    <div className="coordTitle">
                        <p className="Titulos SecondaryColor StandardBoldText">Coordinadores</p>
                    </div>
                    <div className="arrowLeftCoord">
                        <img
                            src={backButtonDisabled ? ButtonBackDisabled : ButtonBack}
                            alt="Back Button"
                            className="IconArrowButton"
                            onClick={() => UpdateButtonStatus(selectedCoordIndex - 1)}
                        />
                    </div>
                    <div className="coordImageContainer">
                        <Grid
                        container
                        direction="row"
                        justify="center"
                        >
                            {dataCoordinadores[selectedCoordIndex].map(element => {
                                return <Grid item xs={12} sm={6} key={element.id}>
                                    <ImageWithText 
                                        isMobile={isMobile}
                                        image={element.picture} 
                                        name={element.name}
                                        text={element.text}
                                        cardPos={element.cardPos}/>
                                </Grid>
                            })}
                        </Grid>
                    </div>
                    <div className="arrowRightCoord">
                        <img
                            src={nextButtonDisabled ? ButtonNextDisabled :ButtonNext}
                            alt="Next Button"
                            className="IconArrowButton"
                            onClick={() => UpdateButtonStatus(selectedCoordIndex + 1)}
                        />
                    </div>
                </div>

                <Grid
                className={useStyles.root} 
                container
                justify="center"
                >
                    <Grid 
                        item xs={12}
                        container
                        id="contactoBlock"
                        className="panel">
                        <Contacto isMobile={isMobile}/>
                    </Grid>
                </Grid>
            </div>;
};

export default NuestroEquipoScreen;
