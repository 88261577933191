import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    MaterialButtonRoot: {
      background: 'linear-gradient(60deg, #1DCAD3 90%, #26cad5 70%)',
      borderRadius: 3,
      border: 0,
      color: 'white',
      height: 48,
      padding: '0 30px',
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      justifyContent: 'center',
    },
}));

export const useStylesButton = makeStyles({
  root: {
    background: '#1DCAD3',
    borderRadius: 3,
    border: 0,
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    justifyContent: 'center',
  },
  label: {
    textTransform: 'capitalize',
    color: 'white',
    justifyContent: 'center',
    fontSize: '30px',
  },
});

export const iconButtonStyle = {
  button: {
      width: 50, height: 50,
      padding: 0
  },
};

export const gridGeneralStyle = {
  Grid: {
    maxHeight: '200px',
  }, 
};

export const buttonMaterialStyle = {
  button: {
    width: 500 , height: 80,
    justifyContent: 'center',
    marginBottom: '300px',
    borderRadius: 0,
    boxShadow: "none",
  },
}

