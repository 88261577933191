import styled from 'styled-components';

export const ContactoContainer = styled.div`
  max-width: 100%;
  height: 100%;
  min-height: auto;
  max-height: 70vh;
  display: flex;
  flex-direction: row;
  /* CONTACTO INICIO */
  .contactoIzquierda {
    width: 50%;
    display: flex;
    flex-direction: column;
    background-color: #1DCAD3;
    max-height: 100%;
    justify-content: center;
  }
  .contactoDerecha {
    width: 50%;
    display: flex;
    flex-direction: column;
    background-color: #00758d;
    max-height: 100%;
    align-items: center;
    justify-content: center;
  }
  .contactoRow {
    display: flex;
    width: 60%;
    height: auto;
    padding-right: 40px;
    padding-left: 40px;
    flex-wrap: wrap;
    margin: 35px 0px 0px 10%;
  }
  .contactoRow2 {
    display: flex;
    width: 60%;
    height: auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    img {
      width: 36px;
      height: auto;
      background-color: #00758d;
    }

    a {
      background-color: #00758d;
      border: none;
      margin: 20px 5px;
    }
  }
  .inputContainer {
    border: 2px solid #1DCAD3;
    width: 60%;
    max-height: 35px;
    display: flex;
    flex-direction: row;
    margin: 20px 0px;

    #inputButton {
      max-height: 100%;
      width: 60px;
      background-color: #1DCAD3;
      border: none;
      margin: 0px;
    }

    #inputImage {
      max-height: 100%;
      background-color: #1DCAD3;
    }

    input {
      background-color: #00758d;
      border: none;
      flex: auto;
      color: #ffffff;
      padding: 5px;

      ::placeholder {
        color: #ffffff;
      }
    }
  }
  #copyright {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #9debf0;
    width: 90%;
    word-wrap: break-word;
    padding-bottom: 10px;
    margin-top: 20px;
  }
  .links {
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    width: 50%;
    word-wrap: break-word;
    margin: 5px 0px;
    cursor: pointer;
  }
  #logo {
    width: 65%;
    margin-bottom: 20px;
  }
  #encabezado {
    font-size: 45px;
    width: 60%;
    word-wrap: break-word;
    color: #ffffff;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 30px;
  }
  #frase {
    width: 90%;
    font-size: 22px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    word-wrap: break-word;
  }
  /* CONTACTO FIN */

  @media (max-width: 1600px) {
    .links {
      font-size: 17px;
    }

    #encabezado {
      font-size: 35px;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    #frase {
      font-size: 17px;
    }
  }

  @media (max-width: 1025px) {
    .links {
      font-size: 17px;
    }
    #encabezado {
      line-height: 1;
      font-size: 32px;
      width: 80%;
      margin-top: 0px;
      margin-bottom: 15px;
    }
    #frase {
      font-size: 14px;
      width: 100%;
    }

    .inputContainer {
      width: 90%;

      #inputButton {
        max-height: 100%;
        width: 60px;
        background-color: #1DCAD3;
        border: none;
        margin: 0px;
      }

      #inputImage {
        max-height: 100%;
        background-color: #1DCAD3;
      }
    }
  }

  @media (max-width: 900px) {
    .contactoRow {
      width: 90%;
    }
  }

  @media (min-width: 300px) and (max-width: 400px) {

    .contactoIzquierda {
      margin-top: 200px !important;
    }

    @media (min-height: 680px) {
      .contactoIzquierda {
        margin-top: 0px !important;
      }
    }

  }

  @media (min-width: 400px) and (max-width: 450px) {
    .contactoIzquierda {
      margin-top: 70px !important;
    }

    @media (min-height: 750px) {
      .contactoIzquierda {
        margin-top: 0px !important;
      }

      #masNoticias {
        top: 338px !important;
      }
    }
  }

  @media (min-width: 450px) and (max-width: 550px) {
    .contactoIzquierda {
      margin-top: 163px !important;
    }
  }

  @media (max-width: 760px) {
    height: auto;
    max-height: none;
    display: flex;
    flex-direction: column;
    /* CONTACTO INICIO */

    .contactoIzquierda {
      width: 100%;
      max-height: none;
      background-color: #1DCAD3;
      justify-content: center;
      align-items: center;
    }

    .contactoDerecha {
      width: auto;
      min-height: 30vh;
      padding: 3em;
    }

    #copyright {
      font-size: 12px;
      width: 100% !important;
      margin-bottom: 5em;
      margin-top: 25px;
      text-align: center;
    }

    .contactoRow {
      padding: 0;
      margin: 10px 0;
      width: 60% !important;
      justify-content: center;
    }

    .links {
      text-align: center;
      font-size: 13px !important;
      width: 100%;
    }

    .inputContainer {
      width: 80%;

      input {
        height: 18px;
        padding: 5px 0px 0px 11px;
      }
    }

    .linkContainer {
      display: grid;
      justify-items: center;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 2px;
      grid-row-gap: 5px;
    }

    .copyright {
      width: 80% !important;
    }

    .grid1 {
      grid-area: 1 / 1 / 2 / 2;
    }
    .grid2 {
      grid-area: 1 / 2 / 2 / 3;
    }
    .grid3 {
      grid-area: 1 / 3 / 2 / 4;
    }
    .grid4 {
      grid-area: 2 / 1 / 3 / 4;
    }
    .grid5 {
      grid-area: 3 / 1 / 4 / 4;
    }

    #logo {
      margin-top: 65px;
    }

    #encabezado {
      font-size: 1em !important;
      /*padding-top: 3em;*/
      margin: 0 !important;
    }

    #frase {
      font-size: 14px !important;
    }

    .contactoRow2 {
      width: 100%;

      img {
        width: 25px;
        height: auto;
      }

      a {
        background-color: #00758d;
        border: none;
        margin: 20px 5px;
      }
    }
  }
  
  
`;
