import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Carousel } from 'react-responsive-carousel';
import { dejarHuellaList } from '../../constants/dejarHuella';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import ButtonNext from '../../images/iconos/botonSiguienteActivo.png';
import ButtonBack from '../../images/iconos/botonAtrasActivo.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function HuellaCarousel(props) {
  const { url, country, className } = props;
  return (
    <div className={className}>
      <img src={url} alt={country} />
      <p>{country}</p>
    </div>
  );
}

function Next({ next }) {
  return (
    <IconButton onClick={next}>
      <img src={ButtonNext} alt="Next Button" style={{ height: '30px' }} />
    </IconButton>
  );
}

function Prev({ prev }) {
  return (
    <IconButton onClick={prev}>
      <img src={ButtonBack} alt="Prev Button" style={{ height: '30px' }} />
    </IconButton>
  );
}

const StyledHuellaCarousel = styled(HuellaCarousel)`
  position: relative;

  p {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fafafa;
    font-size: 4em;
  }
`;

export default function MobileDejarHuella() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showBackButton, setShowBackButton] = useState(false);

  useEffect(() => {
    if (currentSlide === 4) {
      setShowBackButton(true);
    } else if (currentSlide === 0) {
      setShowBackButton(false);
    }
  }, [currentSlide]);

  const handleChangeInSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };

  return (
    <Grid
      container
      className="panel"
      id="huellaBlock"
      direction="row"
      justify="center"
    >
      <h2 className="sectionTitle">¿Dónde quieres dejar huella?</h2>
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        dynamicHeight={false}
        selectedItem={currentSlide}
        onChange={handleChangeInSlide}
      >
        {dejarHuellaList.map((huella) => (
          <StyledHuellaCarousel
            url={huella.url}
            country={huella.country}
            key={huella.country}
          />
        ))}
      </Carousel>
      {showBackButton ? <Prev prev={prev} /> : <Next next={next} />}
    </Grid>
  );
}
