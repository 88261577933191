import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Carousel } from 'react-responsive-carousel';
import { vivenciasDeVoluntarios } from '../../constants/vivenciaDeVoluntarios';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import ButtonNext from '../../images/iconos/botonSiguienteActivo.png';
import ButtonBack from '../../images/iconos/botonAtrasActivo.png';

function VivenciasCarousel(props) {
  const { imgSrc, title, className } = props;
  return (
    <div className={className}>
      <img src={imgSrc} alt={title} />
    </div>
  );
}

function VivenciasText(props) {
  const { title, subtitle, description, className } = props;
  return (
    <div className={className}>
      <h2 className="title">{title}</h2>
      <h4 className="subTitle">{subtitle}</h4>
      <p className="description">{description}</p>
    </div>
  );
}

function Next({ next }) {
  return (
    <IconButton onClick={next}>
      <img src={ButtonNext} alt="Next Button" style={{ height: '30px' }} />
    </IconButton>
  );
}

function Prev({ prev }) {
  return (
    <IconButton onClick={prev}>
      <img src={ButtonBack} alt="Prev Button" style={{ height: '30px' }} />
    </IconButton>
  );
}

const StyledVivenciasText = styled(VivenciasText)`
  background-color: #f9f7f1;
  padding: 10px 20px;
  min-height: 250px;

  .title {
    color: #1DCAD3;
    font-size: 1em;
    margin: 10px 0;
    font-weight: 500;
  }

  .subTitle {
    font-weight: bold;
    font-size: 1.4em;
    margin: 10px 0;
  }

  .description {
    font-size: 1em;
    margin: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 8;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
`;

export default function MobileDejarHuella() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showBackButton, setShowBackButton] = useState(false);

  useEffect(() => {
    if (currentSlide === 5) {
      setShowBackButton(true);
    } else if (currentSlide === 0) {
      setShowBackButton(false);
    }
  }, [currentSlide]);

  const handleChangeInSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };

  return (
    <Grid
      container
      className="panel huellaBlock"
      id="huellaBlock"
      direction="row"
      justify="center"
    > 
      <div className="sectionTitleWrapper">
        <h2 className="sectionTitle">Lo que viven nuestros voluntarios</h2>
      </div>
      <div style={{ padding: '0 4rem' }}>
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          dynamicHeight={false}
          selectedItem={currentSlide}
          onChange={handleChangeInSlide}
        >
          {vivenciasDeVoluntarios.map((vivencia) => (
            <VivenciasCarousel key={vivencia.title} {...vivencia} />
          ))}
        </Carousel>
        <StyledVivenciasText {...vivenciasDeVoluntarios[currentSlide]} />
      </div>
      {showBackButton ? <Prev prev={prev} /> : <Next next={next} />}
    </Grid>
  );
}
