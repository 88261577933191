import React, {useState} from "react";
import { Grid } from "@material-ui/core";
import { InnovacionContainer } from "./styledInnovacion.js";
import ModalEnConstruccion from "./ModalEnConstruccion/ModalEnConstruccion.js";

const Innovacion = props => {
  const [construccionModal, setConstruccionModal] = useState(false);

  return (
    <InnovacionContainer>
      <Grid item xs={12} className="innovacionTop">
        <img
          src="https://tierradeartistasimgs.s3.amazonaws.com/images/Consultorias/Imagen_Consultoria-Empresarial.png"
          className="innovacionImage"
          alt="Consultoria empresarial"
        />
        <div className="innovacionInfoContainer">
          <h2 id="innovacionTitle">Consultoría Empresarial</h2>
          <p>
            Somos expertos en innovación social y design thinking enfocado en
            problemáticas sociales.
          </p>
          <p>
            Si eres parte de una empresa, institución o entidad de gobierno que
            busca crear vínculos con su comunidad y ser socialmente responsable;
            nosotros podemos ayudarte.
          </p>
          <button 
            onClick={(e) => {
                  window.location = "mailto:dabella@tierradeartistas.org";
                  e.preventDefault();
              }}
            >Trabajemos juntos</button>
        </div>
      </Grid>
      <Grid item xs={12} className="innovacionBottom">
        <img
          src="https://tierradeartistasimgs.s3.amazonaws.com/images/Consultorias/Imagen_Consultoria-Academica.png"
          className="innovacionImage responsive"
          alt="Consultoria empresarial"
        />
        <div className="innovacionInfoContainer">
          <h2 id="innovacionTitle2">Consultoría Académica</h2>
          <p>
            Podemos diseñar programas para que tus estudiantes vivan
            experiencias que los sensibilicen a las problemáticas sociales, se
            vuelvan empáticos y líderes con sentido humano.</p><p>Ofrecemos
            conferencias y talleres de Design Thinking enfocado en problemáticas
            sociales y aplicado en campo. Aplica para estudiantes, profesores y
            staff académico.
          </p>
          <button 
            onClick={(e) => {
              window.location = "http://bit.ly/Viajes2021TDA";
              e.preventDefault();
            }}
            >Conoce más</button>
        </div>
        <img
          src="https://tierradeartistasimgs.s3.amazonaws.com/images/Consultorias/Imagen_Consultoria-Academica.png"
          className="innovacionImage noResponsive"
          alt="Consultoria empresarial"
        />
      </Grid>
      <ModalEnConstruccion open={construccionModal} setOpen={setConstruccionModal}/>
    </InnovacionContainer>
  );
};
export default Innovacion;
