import React, { useState, useEffect } from 'react';
import { VivenciasContainer } from './styledVivencias.js';
import VivenciasStepper from './VivenciasStepper.js';

const Vivencias = (props) => {
  const clientWidth = window.innerWidth;
  const [responsive, setResponsive] = useState(false);

  const vivencias = [
    {
      title: '< SAN COSME | BCS',
      subtitle: `Laura Melendez`,
      imgSrc:
        'https://tierradeartistasimgs.s3.amazonaws.com/images/Testimonios/Imagen_Testimonios-Laura.png',
      description: `"Ha sido uno de mis mejores viajes, no solo pude servir 
      a una comunidad, sino que también aprendí mucho sobre mi misma. Conocí personas increíbles que me mostraron otra manera de ver el mundo. Este viaje siempre tendrá un lugar 
      especial en mi corazón."`,
    },
    {
      title: 'AMAZONÍA PERUANA | PERÚ >',
      subtitle: `Paola Bejarano`,
      imgSrc:
        'https://tierradeartistasimgs.s3.amazonaws.com/images/Testimonios/Imagen_Testimonios-Paola.png',
      description: `“Ir de viaje con Tierra de 
      Artistas fue volver a ser una niña. Aventarse a lo desconocido, disfrutar de las pequeñas cosas, nadar en el río, tener nervio de los animales, pasear y ser aventurero, 
      disfrutar de un abrazo y una sonrisa. Una experiencia 
      inolvidable que queda en mi corazón por siempre.”`,
    },
    {
      title: '^ SANAHCAT | YUCATÁN',
      subtitle: `Adonay Gutierrez`,
      imgSrc:
        'https://tierradeartistasimgs.s3.amazonaws.com/images/Testimonios/Imagen_Testimonios-Adonay.png',
      description: `“Conocer gente que comparte
      una visión similar a la tuya con la que puedes converger y forjar una conexión que trasciende a una 
      familia con la cual generas recuerdos inolvidables. Esta foto la tomamos cuando finalizamos nuestro 
      proyecto.”`,
    },
    {
      title: '< LAGO TITICACA | PERÚ',
      subtitle: `Mauricio Vargas`,
      imgSrc:
        'https://tierradeartistasimgs.s3.amazonaws.com/images/Testimonios/Imagen_Testimonios-Mauricio.png',
      description: `"No hay sentimiento equiparable al sentirse en casa tan lejos de casa. Vivir la 
      experiencia del Lago Titicaca más que ser un proyecto de arte, es una experiencia de vida. 
      Gracias, Tierra de Artistas por permitirme entrar libremente hasta la cocina siendo tan bienvenido."`,
    },
    {
      title: 'LAGO TITICACA | PERÚ >',
      subtitle: `Bereniz Elizondo`,
      imgSrc:
        'https://tierradeartistasimgs.s3.amazonaws.com/images/Testimonios/Imagen_Testimonios-Bereniz.png',
      description: `“Terminé mi voluntariado donde descubrí lo que soy capaz de dar. Estoy segura que aquí 
      empieza todo con broche de oro. Porque sé que puedo dar más de mí, y compartir con los demás muchas cosas más 
      aunque algunos solo busquen un rato de sonrisas y diversión. Definitivamente esta es una de las mejores
      experiencias que he tenido y uno de los viajes más divertidos que he hecho.”`,
    },
    {
      title: '^ ARRAZOLA | OAXACA',
      subtitle: `Lucía Barragán`,
      imgSrc:
        'https://tierradeartistasimgs.s3.amazonaws.com/images/Testimonios/Imagen_Testimonios-Lucia.png',
      description: `“Mi experiencia en Arrazola fue de las mejores cosas que me han pasado. Lo que más me gustó fue
      la gente que conocí, tanto las persona de la comunidad que nos recibieron con los brazos abiertos, como el grupo 
      de voluntarixs con lxs que viajé.”`,
    },
  ];

  useEffect(() => {
    if (clientWidth <= 760) {
      setResponsive(true);
    } else {
      setResponsive(false);
    }
  }, [clientWidth]);

  return responsive ? (
    <VivenciasContainer>
      {vivencias.map((vivencia, index) => {
        return (
          <div
            className="responsiveCardContainer"
            key={index + vivencia.subtitle}
          >
            <img
              className="responsiveImage"
              src={vivencia.imgSrc}
              alt={`Uno de los diferentes tipos de voluntariado:${vivencia.title}`}
            />
            <div className="responsiveDescriptionContainer">
              <h2 className="responsiveCardTitle">{vivencia.title}</h2>
              <h4 className="responsiveCardSubtitle">{vivencia.subtitle}</h4>
              <h4 className="responsiveCardDescription">
                {vivencia.description}
              </h4>
            </div>
          </div>
        );
      })}
    </VivenciasContainer>
  ) : (
    <VivenciasStepper vivencias={vivencias} />
  );
};

export default Vivencias;
