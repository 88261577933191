import React from 'react';
import Grid from '@material-ui/core/Grid';
import Vivencias from '../Vivencias';

export default function VivenciaDeVoluntarios() {
  return (
    <Grid container className="panel" id="vivenciasBlock" direction="row">
      <h2 className="sectionTitle">Lo que viven nuestros voluntarios</h2>
      <Grid
        item
        xs={12}
        md={10}
        lg={9}
        style={{ margin: 'auto', justifyContent: 'center' }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          style={{
            width: '100%',
            margin: 'auto',
            marginTop: '50px',
            marginBottom: '40px',
          }}
        >
          <Vivencias />
        </Grid>
      </Grid>
    </Grid>
  );
}
