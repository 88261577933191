export const viajarConCausas = [
  {
    title: 'Conoce',
    subtitle: `Descubre nuevas culturas desde lo más profundo y crea vínculos para toda la vida. Viaja alrededor del mundo y adéntrate a la comunidad no como 
    turista, si no como uno más de la 
    familia.
    `,
    imgSrc:
      'https://tierradeartistasimgs.s3.amazonaws.com/images/Ilustraciones/ViajarConCausa_FiguraConoce-Aqua.png',
  },
  {
    title: 'Impulsa',
    subtitle: `Es momento de ser solidarios y tomar acción. Realiza un proyecto social en conjunto con la comunidad para 
    empoderarlos, sanar el tejido social 
    y mejorar la calidad de vida de las 
    personas.
    `,
    imgSrc:
      'https://tierradeartistasimgs.s3.amazonaws.com/images/Ilustraciones/ViajarConCausa_FiguraImpulsa-Naranja.png',
  },
  {
    title: 'Transforma',
    subtitle: `Puedes usar tu voz y talentos para hacer de este mundo un lugar mejor. Ayuda a resolver las problemáticas del mundo y vive una transformación 
    interior que te dejará el corazón pleno.
    `,
    imgSrc:
      'https://tierradeartistasimgs.s3.amazonaws.com/images/Ilustraciones/ViajarConCausa_FiguraTransforma-Amarillo.png',
  },
];

export const viajarConCausasMobile = [
  {
    title: 'Conoce',
    subtitle: `Descubre nuevas culturas desde lo más profundo y crea vínculos para toda la vida. Viaja alrededor del mundo y adéntrate a la comunidad no como 
    turista, si no como uno más de la 
    familia.
    `,
    imgSrc:
      'https://tierradeartistasimgs.s3.amazonaws.com/MobileImages/4.Ilustraciones viajar con causa/ViajarConCausa_FiguraConoce-Aqua.png',
  },
  {
    title: 'Impulsa',
    subtitle: `Es momento de ser solidarios y tomar acción. Realiza un proyecto social en conjunto con la comunidad para 
    empoderarlos, sanar el tejido social 
    y mejorar la calidad de vida de las 
    personas.
    `,
    imgSrc:
      'https://tierradeartistasimgs.s3.amazonaws.com/MobileImages/4.Ilustraciones viajar con causa/ViajarConCausa_FiguraImpulsa-Naranja.png',
  },
  {
    title: 'Transforma',
    subtitle: `Puedes usar tu voz y talentos para hacer de este mundo un lugar mejor. Ayuda a resolver las problemáticas del mundo y vive una transformación 
    interior que te dejará el corazón pleno.
    `,
    imgSrc:
      'https://tierradeartistasimgs.s3.amazonaws.com/MobileImages/4.Ilustraciones viajar con causa/ViajarConCausa_FiguraTransforma-Amarillo.png',
  },
];

export const porqueConCausaCounter = [
  {
    title: 'Más de',
    subtitle: `beneficiarios
    `,
    amount: 10000,
    intervalMS: 50,
  },
  {
    title: 'Más de',
    subtitle: `voluntarios`,
    amount: 2500,
    intervalMS: 10,
  },
  {
    title: 'Más de',
    subtitle: `pesos en derrama`,
    subtitle2: `económica a comunidades`,
    amount: 1700000,
    intervalMS: 10000,
  },
  {
    title: 'Más de',
    subtitle: `proyectos con`,
    subtitle2: `impacto social`,
    amount: 160,
    intervalMS: 1,
  },
];
