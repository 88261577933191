import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import VizSensor from "react-visibility-sensor";

const Causa = (props) => {
  const { amount, title, subtitle, subtitle2, intervalMS, smaller } = props;
  const [componentVisible, setComponentVisible] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let myInterval = undefined;
    if (componentVisible && amount > 0 && intervalMS > 0) {
      let done = false;
      myInterval = setInterval(() => {
        if (!done) {
          setCounter((current) => {
            if (current === amount) {
              done = true;
              return current;
            } else {
              return current + (parseInt(intervalMS) || 1);
            }
          });
        } else {
          clearTimeout(myInterval);
        }
      }, 1);
    }
    return () => clearTimeout(myInterval);
  }, [componentVisible, amount, intervalMS]);

  return (
    <VizSensor
      onChange={(isVisible) => {
        setComponentVisible(isVisible);
      }}
    >
      <Grid item xs={6} md={smaller?2:3} lg={smaller?2:3} xl={3} className="counterContent">
        <h4 className="counterCardSubtitle">{title.toUpperCase()}</h4>
        <h2 className="counterCardTitle">{counter.toLocaleString("MXN")}</h2>
        <h4 className="counterCardSubtitle">{subtitle.toUpperCase()}</h4>
        <h4 className="counterCardSubtitle2">{subtitle2.toUpperCase()}</h4>
      </Grid>
    </VizSensor>
  );
};

export default Causa;
