export const dejarHuellaList = [
  {
    url:
      "https://tierradeartistasimgs.s3.amazonaws.com/MobileImages/3.Donde quieres dejar huella/Imagen-DejarHuella_Mexico.png",
    country: "México",
  },
  {
    url:
      "https://tierradeartistasimgs.s3.amazonaws.com/MobileImages/3.Donde quieres dejar huella/Imagen-DejarHuella_Peru.png",
    country: "Perú",
  },
  {
    url:
      "https://tierradeartistasimgs.s3.amazonaws.com/MobileImages/3.Donde quieres dejar huella/Imagen-DejarHuella_Marruecos.png",
    country: "Marruecos",
  },
  {
    url:
      "https://tierradeartistasimgs.s3.amazonaws.com/MobileImages/3.Donde quieres dejar huella/Imagen-DejarHuella_Kenya.png",
    country: "Kenya",
  },
  {
    url:
      "https://tierradeartistasimgs.s3.amazonaws.com/MobileImages/3.Donde quieres dejar huella/Imagen-DejarHuella_Amazonas.png",
    country: "Amazonas",
  },
];
