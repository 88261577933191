import styled from 'styled-components';

export const BlogContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: 75vh;
  padding-bottom: 50px;
  /* BLOG INICIO */
  .blogBlock {
    background-color: #f9f7f1;
  }

  .blogTop {
    width: 100%;
    height: 650px;
    position: relative;
    display: flex;
  }
  .blogImage {
    width: 25%;
    height: auto;
    position: absolute;
    left: 30%;
    top: 0;
  }

  .blogPostInfo {
    width: 30%;
    height: 300px;
    position: absolute;
    left: 46%;
    top: calc((100vw * 0.15) / 1.7 * 1.2);
    color: #373434;
    background-color: #fff;
    padding: 20px;

    a {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: #1DCAD3;
      text-decoration: underline;
    }

    h2 {
      font-size: 19px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: #373434;
    }

    h3 {
      font-size: 19px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: #373434;
    }

    p {
      font-size: 19px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: #373434;
    }
  }

  #masNoticias {
    width: 15%;
    background-color: #ec8117;
    font-size: 22px;
    font-weight: bold;
    padding: 15px 0px;
    color: #ffffff;
    border: none;
    bottom: 0;
    margin: auto;
    left: 42.5%;
    position: absolute;
  }

  @media (min-width: 760px) and (max-width: 1600px) {
    .blogTop {
      width: 100%;
      height: 490px;
      position: relative;
      display: flex;
    }
    .blogPostInfo {
      height: 200px;

      a {
        font-size: 13px;
      }

      h2 {
        font-size: 13px;
      }

      h3 {
        font-size: 12px;
      }

      p {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 1025px) {
    .blogPostInfo {
      p {
        font-size: 12px;
      }
    }
  }


  @media (min-width: 300px) and (max-width: 340px) {
    #masNoticias {
      top: 400px;
    }

  }

  @media (min-width: 340px) and (max-width: 360px) {
    #masNoticias {
      top: 370px;
    }
  }

  @media (min-width: 361px) and (max-width: 380px) {
    #masNoticias {
      top: 345px;
    }
  }


  
  @media (min-width: 380px) and (max-width: 480px) {
    #masNoticias {
      top: 320px;
    }
  }
  
  
  @media (max-width: 760px) {
    padding: 20px 0;

    .blogTop {
      flex-direction: column;
      height: auto;
    }

    .blogImage {
      width: 100%;
      height: auto;
      position: relative;
      top: 0;
      left: 0;
      margin: auto;
    }

    .blogPostInfo {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 91vw;
      top: 65%;
      left: 4%;
      right: 50%;
      padding: 20px;
      height: auto;
      box-sizing: border-box;

      h2 {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 5px;
        padding: 0 10px;
      }

      h3 {
        font-size: 14px;
        margin-top: 5px;
        padding: 0 10px;
      }

      p {
        font-size: 15px;
        margin-bottom: 5px;
        padding: 0 10px;
      }

      a {
        font-size: 15px;
        margin-top: 15px;
        padding: 0 10px;
      }
    }

    #masNoticias {
      width: 40%;
      font-size: 14px;
      position: relative;
      left: 0;
      margin: auto;
      margin-top: 20px;
        padding: 16px;
        bottom: -25vh;
    }
  }
  /* BLOG FIN */
`;
