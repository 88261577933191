import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import IconButton from '@material-ui/core/IconButton';
import ButtonNext from '../images/iconos/botonSiguienteActivo.png';
import ButtonBack from '../images/iconos/botonAtrasActivo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
}));

const buttonStyles = makeStyles({
  root: {
      padding: 0,
      marginTop: "20px",
      marginBottom: "20px",
      marginRight: "20px",
  },
  label: {
      padding: 0, 
  },
});

export default function HuellaCarousel(props) {
  const classes = useStyles();
  const buttonClasses = buttonStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const maxSteps = steps.length;
  const { expeditions } = props;

  const [currLeft, setLeft] = useState(0);
  const [currCenter, setCenter] = useState(1);
  const [currRight, setRight] = useState(2);

  const handleNext = () => {
    if (activeStep + 1 === maxSteps) {
      setActiveStep(0);
      setLeft(0);
      setCenter(1);
      setRight(2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setLeft((prevLeft) => (prevLeft + 1 >= maxSteps ? 0 : prevLeft + 1));
      setCenter((prevCenter) =>
        prevCenter + 1 >= maxSteps ? 0 : prevCenter + 1,
      );
      setRight((prevRight) => (prevRight + 1 >= maxSteps ? 0 : prevRight + 1));
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(maxSteps - 1);
      setLeft(maxSteps - 1);
      setCenter(0);
      setRight(1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setLeft((prevLeft) => (prevLeft - 1 < 0 ? maxSteps - 1 : prevLeft - 1));
      setCenter((prevCenter) =>
        prevCenter - 1 < 0 ? maxSteps - 1 : prevCenter - 1,
      );
      setRight((prevRight) =>
        prevRight - 1 < 0 ? maxSteps - 1 : prevRight - 1,
      );
    }
  };

  useEffect(() => {
    const countries = new Set();
    let expeditionCountries = [];

    if (expeditions.length > 0) {
      console.table(expeditions);
      expeditions.forEach((element) => {
        if (!countries.has(expeditions.country)) {
          expeditionCountries.push({
            imgPath: element.imgSrc,
            label: element.country,
          });
          countries.add(element.country);
        }
      });
    }
    setSteps([...expeditionCountries]);
  }, [expeditions]);

  return (
    <div className={classes.root}>
      <div id="huellaImagesContainer">
        {steps[currLeft] && (
          <div className="labeledImage">
            <img
              className="huellaImage"
              src={steps[currLeft].imgPath}
              alt={steps[currLeft].label}
            />
            <h2>{steps[currLeft].label}</h2>
          </div>
        )}
        {steps[currCenter] && (
          <div className="labeledImage">
            <img
              className="huellaImage"
              src={steps[currCenter].imgPath}
              alt={steps[currCenter].label}
            />
            <h2>{steps[currCenter].label}</h2>
          </div>
        )}
        {steps[currRight] && (
          <div className="labeledImage">
            <img
              className="huellaImage"
              src={steps[currRight].imgPath}
              alt={steps[currRight].label}
            />
            <h2>{steps[currRight].label}</h2>
          </div>
        )}
      </div>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <IconButton 
            classes={{
                root: buttonClasses.root, // class name, e.g. `classes-nesting-root-x`
                label: buttonClasses.label, // class name, e.g. `classes-nesting-label-x`
            }}
            onClick={handleNext}>
            <img
              src={ButtonNext}
              alt="Next Button"
              style={{ height: '50px' }}
            />
          </IconButton>
        }
        backButton={
          <IconButton 
            classes={{
                root: buttonClasses.root, // class name, e.g. `classes-nesting-root-x`
                label: buttonClasses.label, // class name, e.g. `classes-nesting-label-x`
            }}
            onClick={handleBack}>
            <img
              src={ButtonBack}
              alt="Back Button"
              style={{ height: '50px' }}
            />
          </IconButton>
        }
        id="huellaCarousel"
      />
    </div>
  );
}
