
import React, { useState } from "react";
import ImageIconSlider from '../../components/ImageIconSlider/ImageIconSlider';
import TitleField from '../../components/TitleField/TitleField';
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {useStyles} from './ImpactoStyles';
import {data, ImpactoBanner, ImpactoEconomicoIcon, ImpactoComunidad} from './data';
import NavBar from "../../components/Navbar.js";
import Contacto from "../../components/Contacto.js";
import styled from 'styled-components';

import './Impacto.css';
import './../../AppStyles.css';

import ButtonNext from '../../images/iconos/botonSiguienteActivo.png';
import ButtonBack from '../../images/iconos/botonAtrasActivo.png';

const StyledLandingBlock = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  min-height: 97vh !important;
  @media (min-width: 1300px) {
    max-width: 100%;
    margin: auto;
  }
`;

const ImageWithText = (props) => {
    let itemOrientation = <div className="horizontalContainer">
        <div className="imageInLeft">
            <img src={props.image} alt="hozImg" className="imgLeft"/>
        </div>
        <div className="textInRight">
            <div className="putToMiddleText">
                <div className="Titulos SecondaryColor StandardBoldText">{props.title}</div>
                <div className="Informacion">{props.text}</div>
            </div>
        </div>
    </div>;

    if(props.orientation === "left"){
        itemOrientation = <div className="horizontalContainer">
            <div className="textInLeft">
                <div className="putToMiddleText">
                    <div className="Titulos SecondaryColor StandardBoldText">{props.title}</div>
                    <div className="Informacion">{props.text}</div>
                </div>
            </div>
            <div className="imageInRight">
                <img src={props.image} alt="hozImg" className="imgRight"/>
            </div>
        </div>;
    }
    return itemOrientation;
};

const buttonStyles = makeStyles({
    root: {
        padding: 0,
        marginLeft: "20px",
    },
    label: {
        padding: 0, 
    },
});

const ImpactoScreen = (props) => {
    //---------------------------------------------------------------------------
    // Text Constants
    //---------------------------------------------------------------------------
    const TitleText = (<p> El impacto de Tierra de Artistas es la <br/> suma de todas las personas que han <br/> dado su tiempo y compartido su talento. </p>);
    const isMobile = useMediaQuery("(max-width:760px)");
    const buttonClasses = buttonStyles();
    let currentSlides = 3;

    if(isMobile)
        currentSlides = 7;

    //---------------------------------------------------------------------------
    // useState Hook
    //---------------------------------------------------------------------------
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const [sliderData] = useState({
            properties: data?.properties || [],
            property: data?.properties[selectedImageIndex + 1 < (data?.properties?.length) ? selectedImageIndex + 1 : selectedImageIndex ] || [],
    });

    //---------------------------------------------------------------------------
    // Functions
    //---------------------------------------------------------------------------
    const nextProperty = () => {
        let newIndex = selectedImageIndex + 1;
        
        if(newIndex < (currentSlides)){
            setSelectedImageIndex(newIndex);
        }else if (newIndex === currentSlides){
            newIndex = 0;
            setSelectedImageIndex(newIndex);
        }
    }

    const prevProperty = () => {
        const newIndex = selectedImageIndex - 1;
        
        if(newIndex >= 0){
            setSelectedImageIndex(newIndex);
        }
    }

    return <div className="Impacto">
                <NavBar isMobile={isMobile} showCovid={true} fillColor={"#1DCAD3"} isHome={true} />
                
                <StyledLandingBlock imageUrl={ImpactoBanner}>
                    <div className="bannerTitle">
                        <TitleField text={TitleText} color="alternative"/> 
                    </div>
                </StyledLandingBlock>
                
                <div className="container fullView">
                    <img src={ImpactoEconomicoIcon} alt="BannerImpacto" className="impactoEconomicoIcon"/>
                    <div className=" AlternativeColor circleData">
                        <p className="Titulos StandardBoldText"> 
                            +1 MDP
                        </p>
                        <p className="Informacion ">
                            En derrama <br/> económica
                        </p>
                    </div>
                    <div className="topLeft">
                        <p className="ThemeColor NumeroTitulo">01</p>
                        <p className="Titulos SecondaryColor StandardSpacingText StandardBoldText"> 
                            Impacto <br/> económico 
                        </p>
                        <p className="Informacion dynamicSpaceTexto StandardSpacingText StandardBoldText">
                            Renumerarle a las personas de las comunidades es <br/>
                            indispensable e independiente del proyecto que se <br/>
                            realiza. 
                        </p>
                        <p className="TextoCorrido dynamicSpaceTexto">
                            Pagamos por comida <br/>
                            y hospedaje. <br/>
                        </p>
                        <p className="TextoCorrido dynamicSpaceTexto">
                            También pagamos por <br/>
                            los talleres que nos <br/>
                            imparte. <br/>
                        </p>
                        <p className="TextoCorrido dynamicSpaceTexto">
                            Pagamos por tours <br/>
                            y experiencias <br/>
                            culturales. <br/>
                        </p>
                    </div>
                </div>
                <div className="container fulldynamicView">
                    <div className="topLeft">
                        <p className="ThemeColor NumeroTitulo">02</p>
                        <p className="Titulos SecondaryColor StandardSpacingText StandardBoldText"> 
                            Impacto en <br/> la comunidad 
                        </p>
                    </div>
                </div>

                { ImpactoComunidad.map(element => {
                    return <ImageWithText  
                            key= {element["_id"]}
                            title = {element["title"]}
                            text = {element["text"]}
                            orientation = {element["orientation"]}
                            image = {element["picture"]} />
                })}

                <div className="container customBottomSection halfView">
                    <div className="topLeft">
                        <p className="ThemeColor NumeroTitulo">03</p>
                        <p className="Titulos SecondaryColor StandardSpacingText StandardBoldText"> 
                            Impacto personal
                        </p>
                        <p className="Informacion StandardSpacingText">
                            Los voluntarios que participan en nuestros proyectos <br/>
                            dejan huella y crean un impacto positivo ante proble- <br/>
                            máticas sociales, amplían su perspectiva del mundo al <br/>
                            viajar y crecen como personas al hacer un trabajo de <br/>
                            introspección.
                        </p>
                    </div>
                </div>
                
                <ImageIconSlider 
                    isMobile = {isMobile}
                    properties={sliderData.properties} 
                    currIndex={selectedImageIndex} 
                    nextIndex={nextProperty}
                    prevIndex={prevProperty}/> 

                <div className="horizontalContainer">
                    {selectedImageIndex !== 0  && <div className="arrowLeft">
                            <IconButton aria-label="back" 
                                onClick={prevProperty}
                                classes={{
                                    root: buttonClasses.root, // class name, e.g. `classes-nesting-root-x`
                                    label: buttonClasses.label, // class name, e.g. `classes-nesting-label-x`
                                }}
                                > 
                                <img
                                    src={ButtonBack}
                                    alt="Back Button"
                                    className="IconArrowButton"
                                />
                            </IconButton>
                        </div>
                    }
                    {selectedImageIndex !== (currentSlides - 1) && <div className="arrowRight">
                            <IconButton aria-label="back" 
                                onClick={nextProperty}
                                classes={{
                                    root: buttonClasses.root, // class name, e.g. `classes-nesting-root-x`
                                    label: buttonClasses.label, // class name, e.g. `classes-nesting-label-x`
                                }}
                                > 
                                <img
                                    src={ButtonNext}
                                    alt="Next Button"
                                    className="IconArrowButton"
                                />
                            </IconButton>
                        </div>
                    }
                </div>

                <div className="horizontalContainer"></div>

                <Grid
                className={useStyles.root} 
                container
                justify="center"
                >
                    <Grid item >
                        <button 
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href='http://bit.ly/Viajes2021TDA';
                                }}
                            className="WideButton PrimaryBackgroundColor Informacion">Deja huella</button>
                    </Grid>
                    <Grid 
                        item xs={12}
                        container
                        id="contactoBlock"
                        className="panel">
                        <Contacto isMobile={isMobile}/>
                    </Grid>
                </Grid>
            </div>;
};

export default ImpactoScreen;
