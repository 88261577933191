import React from "react";

import Daniela from '../../images/nuestroEquipo/equipo/DanielaAbella.png';
import DanielaFiltro from '../../images/nuestroEquipo/equipo/DanielaAbella_Filtro.png';
import Emilie from '../../images/nuestroEquipo/equipo/EmilieCardem.png';
import EmilieFiltro from '../../images/nuestroEquipo/equipo/EmilieCardem_Filtro.png';
import FridaHaaz from '../../images/nuestroEquipo/equipo/FridaHaaz.png';
import FridaHaazFiltro from '../../images/nuestroEquipo/equipo/FridaHaaz_Filtro.png';
import LuisSilva from '../../images/nuestroEquipo/equipo/LuisSilva.png';
import LuisSilvaFiltro from '../../images/nuestroEquipo/equipo/LuisSilva_Filtro.png';
import PaolaGarcia from '../../images/nuestroEquipo/equipo/PaolaGarcia.png';
import PaolaGarciaFiltro from '../../images/nuestroEquipo/equipo/PaolaGarcia_Filtro.png';

import ComillasAbiertasIcon from '../../images/nuestroEquipo/Comillas_Abiertas.png';
import ComillasCierreIcon from '../../images/nuestroEquipo/Comillas_Cierre.png';

import coord1Diana from '../../images/nuestroEquipo/coordinadores/Parte1/DianaMontañez.png';
import coord1Erick from '../../images/nuestroEquipo/coordinadores/Parte1/ErickDominguez.png';
import coord1Laura from '../../images/nuestroEquipo/coordinadores/Parte1/LauraMelendez.png';
import coord1Renato from '../../images/nuestroEquipo/coordinadores/Parte1/RenatoGarcia.png';
import coord2JorgeO from '../../images/nuestroEquipo/coordinadores/Parte2/JorgeOliver.png';
import coord2JorgeW from '../../images/nuestroEquipo/coordinadores/Parte2/JorgeWong.png';
import coord2MaJose from '../../images/nuestroEquipo/coordinadores/Parte2/MaJoseBarrientos.png';
import coord2Ximena from '../../images/nuestroEquipo/coordinadores/Parte2/XimenaVictoria.png';
import coord3Daniela from '../../images/nuestroEquipo/coordinadores/Parte3/DanielaGarcia.png';
import coord3Erick from '../../images/nuestroEquipo/coordinadores/Parte3/ErickPuttzis.png';
import coord3Gabriela from '../../images/nuestroEquipo/coordinadores/Parte3/GabrielaAhuactzin.png';
import coord3Tere from '../../images/nuestroEquipo/coordinadores/Parte3/TerePlatas.png';
import coord4Abigail from '../../images/nuestroEquipo/coordinadores/Parte4/AbigailCantu.png';
import coord4Leny from '../../images/nuestroEquipo/coordinadores/Parte4/LenyEstrada.png';
import coord4Luciana from '../../images/nuestroEquipo/coordinadores/Parte4/LucianaGaleazzi.png';
import coord4XNadhxiely from '../../images/nuestroEquipo/coordinadores/Parte4/NadhxielyMartinez.png';
import coord5Belen from '../../images/nuestroEquipo/coordinadores/Parte5/BelenOlalde.png';
import coord5Carlos from '../../images/nuestroEquipo/coordinadores/Parte5/CarlosMedina.png';
import coord5Fernanda from '../../images/nuestroEquipo/coordinadores/Parte5/FernandaRauch.png';
import coord5Thania from '../../images/nuestroEquipo/coordinadores/Parte5/ThaniaGarcia.png';
import coord6Ivonn from '../../images/nuestroEquipo/coordinadores/Parte6/IvonnGonzalez.png';
import coord6Mauricio from '../../images/nuestroEquipo/coordinadores/Parte6/MauricioVargas.png';

export const ComillasAbiertas = ComillasAbiertasIcon;
export const ComillasCierre = ComillasCierreIcon;

export const dataEquipo = [
    {
        "id": "593e9297e17df20c4237d42",
        "index": 0,
        "picture": Daniela,
        "pictureFiltro": DanielaFiltro,
        "name": <p>DANIELA <br/> ABELLA</p>,
        "text": "Sonríe, aprende y déjate sorprender",
        "position": "Co-Dirección General y Finanzas.",
        "cardPos" : "center",
    },
    {
        "id": "53e9297e17df20c4a23742",
        "index": 0,
        "picture": FridaHaaz,
        "pictureFiltro": FridaHaazFiltro,
        "name": <p>FRIDA <br/> HAAZ</p>,
        "text": "Usa tu voz y talentos para hacer de este mundo un lugar lleno de amor y bondad",
        "position": "Fundadora y Co-Dirección General.",
        "cardPos" : "down",
    },
    {
        "id": "939297e17df20c4a237d42",
        "index": 0,
        "picture": PaolaGarcia,
        "pictureFiltro": PaolaGarciaFiltro,
        "name": <p>PAOLA <br/> GARCÍA</p>,
        "text": "Que todo lo que des encuentre mil maneras de regresar a ti",
        "position": "Dirección de Viajeros con Causa.",
        "cardPos" : "center",
    },
    {
        "id": "593e997e17df2c4a237d42",
        "index": 0,
        "picture": Emilie,
        "pictureFiltro": EmilieFiltro,
        "name": <p>EMELIE <br/> CARDEM</p>,
        "text": "Ser bondadoso no es un talento, es una virtud de los valientes.",
        "position": "Dirección de Empresas con Causa",
        "cardPos" : "center",
    },
    {
        "id": "593e9297edf20c4a23742",
        "index": 0,
        "picture": LuisSilva,
        "pictureFiltro": LuisSilvaFiltro,
        "name": <p>LUIS <br/> SILVA</p>,
        "text": "La amabilidad es la mejor cualidad de una persona",
        "position": "Dirección de Capítulo Estudiantil.",
        "cardPos" : "center",
    },
]

export const dataCoordinadores = [
    [
        {
            "id" : "593e9297e17df20ca23742",
            "picture": coord1Laura,
            "name": "LAURA MELÉNDEZ",
            "text": "Se color en un mundo de blanco y negro.",
        },
        {
            "id" : "53e9297e17df20c4a23742",
            "picture": coord1Renato,
            "name": "RENATO GARCÍA",
            "text": "Las emociones no son símbolo de debilidad sino de fortaleza.",
            "cardPos": "down"
        },
        {
            "id" : "593e9297e17df20c4a3742",
            "picture": coord1Erick,
            "name": "ERICK DOMÍNGUEZ",
            "text": "El arte con tanto para dar, y yo con tan poco que decir.",
        },
        {
            "id" : "593e9297e17df20c4a2372",
            "picture": coord1Diana,
            "name": "DIANA MONTAÑEZ",
            "text": "Nuestra alma es un gran artista, llenemos de arte los corazones.",
            "cardPos": "down"
        },
    ],
    [
        {
            "id" : "59e9297e17df20c4a23742",
            "picture": coord2MaJose,
            "name": "MA. JOSÉ BARRIENTOS",
            "text": "No necesitas ser perfecto, lo único que necesitas es ser TÚ.",
        },
        {
            "id" : "593e929717df20c4a23742",
            "picture": coord2Ximena,
            "name": "XIMENA VICTORIA",
            "text": "Haz siempre lo que haga brillar tu alma y acelerar tu corazón.",
            "cardPos": "down"
        },
        {
            "id" : "593e9297e17df20c45a23742",
            "picture": coord2JorgeO,
            "name": "JORGE OLIVIER",
            "text": "En los colores es donde nuestro cerebro conoce el universo.",
        },
        {
            "id" : "5935e9297e17df20c4a23742",
            "picture": coord2JorgeW,
            "name": "JORGE WONG",
            "text": "Las imperfecciones no nos determinan, es tu actitud ante el problema.",
            "cardPos": "down"
        },
    ],
    [
        {
            "id" : "593e9297e17d4f20ca23742",
            "picture": coord3Daniela,
            "name": "DANIELA GARCÍA",
            "text": "Optimismo y buena cara ante la vida te llevará hasta las nubes.",
        },
        {
            "id" : "93e9297e17df20c4a23742",
            "picture": coord3Gabriela,
            "name": "GABRIELA AHUACTZIN",
            "text": "Todos tenemos ese algo que necesita ser escuchad por el mundo.",
            "cardPos": "down"
        },
        {
            "id" : "593e9297e17df20c4a2374",
            "picture": coord3Erick,
            "name": "ERICK PUTTZIS",
            "text": "No se trata de ser diferente, se trata de ser uno mismo.",
        },
        {
            "id" : "593e9297e17df20c4a242",
            "picture": coord3Tere,
            "name": "TERE PLATAS",
            "text": "Sueños gigantes, un mundo enorme y unos cortos pies. Mejor nos ponemos en marcha.",
            "cardPos": "down"
        },
    ],
    [
        {
            "id" : "593e97e17df20c4a23742",
            "picture": coord4Abigail,
            "name": "ABIGAIL CANTÚ",
            "text": "Ama cada color de tu alma, cada uno te da ese brillo que te define.",
        },
        {
            "id" : "593e9297e17df20a23742",
            "picture": coord4XNadhxiely,
            "name": "NADHXIELY MARTÍNEZ",
            "text": "Nunca dejes de creer, en lo que sea, es lo que nos mantiene vivos.",
            "cardPos": "down"
        },
        {
            "id" : "593e92917df20c4a23742",
            "picture": coord4Luciana,
            "name": "LUCIANA GALEAZZI",
            "text": "Sé la luz y no tengas miedo de desgastar tu vida por amor.",
        },
        {
            "id" : "593e9297e17df20c23742",
            "picture": coord4Leny,
            "name": "LENY ESTRADA",
            "text": "Muere con recuerdos y no con sueños por cumplir.",
            "cardPos": "down"
        },
    ],
    [
        {
            "id" : "593e9297edf20c4a23742",
            "picture": coord5Belen,
            "name": "BELÉN OLALDE",
            "text": "Improvisa y fluye dentro de tus pasiones.",
        },
        {
            "id" : "593e9297e17df20c4a237",
            "picture": coord5Thania,
            "name": "THANIA GARCÍA",
            "text": "El amor es la única realidad necesaria en la vida.",
            "cardPos": "down"
        },
        {
            "id" : "5e9297e17df20c4a23742",
            "picture": coord5Carlos,
            "name": "CARLOS MEDINA",
            "text": "Dar 20 pasos gigantes o 200 pequeños, el punto es moverte hasta alcanzar tus sueños.",
        },
        {
            "id" : "3e9297e17df20c4a23742",
            "picture": coord5Fernanda,
            "name": "FERNANDA RAUCH",
            "text": "Nunca tengas miedo de ser quien eres ni de gritarlo al mundo con orgullo.",
            "cardPos": "down"
        },
    ],
    [
        {
            "id" : "593e9297e417df20c4a23742",
            "picture": coord6Mauricio,
            "name": "MAURICIO VARGAS",
            "text": "Los pequeños aleteos son los que hacen al mundo más ligero.",
        },
        {
            "id" : "598e9297e17df20c4a23742",
            "picture": coord6Ivonn,
            "name": "IVONN GONZÁLEZ",
            "text": "La vida a veces se siente vacía hasta que tienes la oportunidad de compartirla con más.",
            "cardPos": "down"
        },
    ],
]
