import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  DialogDiv,
  InnerCard,
  CloseButton,
  TitleDiv,
  TitleText,
  RequirementsContainer,
} from './ModalEnConstruccionStyles';
// import serAceptado from './../../images/iconos/SerAceptado.png';
import WebLogo from './../../images/iconos/web_Logo.png';

const ModalRequisitos = (props) => {
  const { open, setOpen } = props;
  return (
    <Dialog
      maxWidth={false}
      open={open}
      PaperComponent={DialogDiv}
      onClose={() => setOpen(false)}
    >
      <InnerCard>
        <TitleDiv>
          <TitleText>Estamos construyendo para tí</TitleText>
        </TitleDiv>
        <RequirementsContainer>
          <img src={WebLogo} alt="WEB logo" />
        </RequirementsContainer>
      </InnerCard>
      <CloseButton
        onClick={() => {
          setOpen(false);
        }}
      />
    </Dialog>
  );
};

export default ModalRequisitos;
