import React from 'react';
import { MainCardContainer, ImageContainer, TextContainer, RequirementText } from './RequirementCardStyle';




const RequirementCard = props => {
    const { text, url } = props;
    return <MainCardContainer>
        <ImageContainer imageUrl={url} />
        <TextContainer>
            <RequirementText>{text}</RequirementText>
        </TextContainer>
    </MainCardContainer>;
}

export default RequirementCard;