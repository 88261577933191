import React from 'react';
import PropTypes from 'prop-types';
import './ImageCard.css';
import '../../../Screens/CaminoRecorrido/CaminoRecorrido.css';
import '../../../AppStyles.css';


const ImageCard = ({property}) => {
    const {index, picture, text, date, title} = property;
    let classesCard = ["card"];

    if(parseInt(date.trim().substr(date.length - 1)) % 2 === 1){
        classesCard.push("card-down");
    }

    return (
        <div id={`card-${index}`} className={classesCard.join(" ")}>
            <img className="Image" src={picture} alt={date} />
            <div className="details Details-Card">
                <p className="Informacion Title-Card ThemeColor">{title} - {date} </p>
                <p className="TextoCorrido">{text}</p>
            </div>
        </div>
    )
}

ImageCard.propTypes = {
    property: PropTypes.object.isRequired
}

export default ImageCard;