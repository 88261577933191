import styled from 'styled-components';
import closeIcon from './../../images/iconos/CerrarTurquesa.png';

export const DialogDiv = styled.div`
  width: 60vw;
  height: auto;
  background-color: none;
  display: flex;
  @media (max-width: 1100px) {
    width: 90vw;
  }
`;

export const InnerCard = styled.div`
  margin-right: 10px;
  margin-top: 10px;
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 60px;
  @media (max-width: 760px) {
    flex-direction: column;
    padding: 20px;
  }
`;

export const TitleDiv = styled.div`
  width: 50%;
  @media (max-width: 760px) {
    flex: 0;
    width: 100%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TitleText = styled.h2`
    font-size: 38px;
    font-weight: 700
    letter-spacing: 1.2px;
    line-height: 1;
    font-family: 'Work Sans', sans-serif;
    max-width: 350px;
    text-align: left;
    margin-bottom: 0px;
    @media (max-width: 760px) {
        font-size: 1.2em;
        max-width: none;
        padding: 10px;
    }
`;
export const SubtitleText = styled.h2`
  font-size: 18px;
  font-weight: 500;
  font-family: 'Work Sans', sans-serif;
  max-width: 350px;
  text-align: left;
  @media (max-width: 760px) {
    font-size: 1.2em;
    max-width: none;
    padding: 10px;
  }
`;

export const FormContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 40px;

  input {
    border: 2px solid #1DCAD3;
    padding: 10px;
    color: #666;
    width: 95%;
    margin: 10px 0px;
  }

  button {
    background-color: #1DCAD3;
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 10px;
    font-size: 18px;
    border-color: transparent;
    margin-top: 20px;
  }

  @media (max-width: 760px) {
    width: 90%;
    margin: 0;
    padding: 50px 20px;
    flex: 1;
  }
`;

export const CloseButton = styled.div`
  width: 55px;
  height: 55px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background-image: url(${closeIcon});
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 760px) {
    width: 40px;
    height: 40px;
  }
`;
