import React from "react";

import Image1 from '../../images/impacto/impactoPersonal/Paso1.png';
import Image2 from '../../images/impacto/impactoPersonal/Paso2.png';
import Image3 from '../../images/impacto/impactoPersonal/Paso3.png';
import Image4 from '../../images/impacto/impactoPersonal/Paso4.png';
import Image5 from '../../images/impacto/impactoPersonal/Paso5.png';
import Image6 from '../../images/impacto/impactoPersonal/Paso6.png';
import Image7 from '../../images/impacto/impactoPersonal/Paso7.png';

import Icon1 from '../../images/impacto/Iconos/Paso1_Icono.png';
import Icon2 from '../../images/impacto/Iconos/Paso2_Icono.png';
import Icon3 from '../../images/impacto/Iconos/Paso3_Icono.png';
import Icon4 from '../../images/impacto/Iconos/Paso4_Icono.png';
import Icon5 from '../../images/impacto/Iconos/Paso5_Icono.png';
import Icon6 from '../../images/impacto/Iconos/Paso6_Icono.png';
import Icon7 from '../../images/impacto/Iconos/Paso7_Icono.png';

import BannerImageImport from '../../images/impacto/Impacto_BannerInicio.png';
import ImpactoEconomicoIconImport from '../../images/impacto/Iconos/ImpactoEconomico_Icono.png';

import ImageAutogestion from '../../images/impacto/impactoEnLaComunidad/Autogestion.png';
import ImageCocreacion from '../../images/impacto/impactoEnLaComunidad/Cocreacion.png';
import ImageColaboracion from '../../images/impacto/impactoEnLaComunidad/Colaboracion.png';
import ImageSentidoDePertenencia from '../../images/impacto/impactoEnLaComunidad/SentidoDePertenecia.png';



//EXPORTS --------

export const ImpactoBanner = BannerImageImport;
export const ImpactoEconomicoIcon = ImpactoEconomicoIconImport;

export const ImpactoComunidad = [
    {
        "_id"       : "593e9297e17df20c4a23742",
        "picture"   : ImageColaboracion,
        "text"      : <p>Realizamos proyectos tangibles enfocados    en las problemáticas de la comunidad.</p>,
        "title"     : <p>Colaboración</p>,
        "orientation" : "left",
    }, 
    {
        "_id"       : "5939297e17df20c4a237d42",
        "picture"   : ImageCocreacion,
        "text"      : <p>Mediante el intercambio de conocimientos y    talentos fomentamos el aprendizaje mutuo.</p>,
        "title"     : <p>Co-creación</p>,
        "orientation" : "right",
    },
    {
        "_id"       : "593e9297e17df2c4a237d42",
        "picture"   : ImageSentidoDePertenencia,
        "text"      : <p>Difundimos su cultura y sus tradiciones.</p>,
        "title"     : <p>Sentido de <br/> pertenencia</p>,
        "orientation" : "left",  
    },
    {
        "_id"       : "593e929e17df20c4a237d42",
        "picture"   : ImageAutogestion,
        "text"      : <p>Asesoramos a las personas de la comunidad    en sus modelos de negocio.</p>,
        "title"     : <p>Autogestión</p>,
        "orientation" : "right",   
    }
];

export const data = {
    "properties": [
    {
        "_id"       : "593e9297e17df20c4a237d42",
        "index"     : 0,
        "picture"   : Image1,
        "icon"      : Icon1,
        "iconPosition" : "left",
        "text"      : "Crecimiento personal (introspección, autoconocimiento, reencuentro contigo mismo).",
    },
    {
        "_id"       : "593e9297e17sdf20c4a237d4j2",
        "index"     : 1,
        "picture"   : Image2,
        "icon"      : Icon2,
        "iconPosition" : "right",
        "text"      : "Conocer nuevas culturas. Ampliar la perspectiva del mundo.",
    },
    {
        "_id"       : "5931e9297557df20c4a2h37d42",
        "index"     : 2,
        "picture"   : Image3,
        "icon"      : Icon3,
        "iconPosition" : "center",
        "text"      : "Intercambio de conocimientos y talentos/aprendizaje mutuo.",
    },
    {
        "_id"       : "593e9297e17df20cf3a2375d42",
        "index"     : 3,
        "picture"   : Image4,
        "icon"      : Icon4,
        "iconPosition" : "right",
        "text"      : "Satisfacción de dejar huella (cambiar el mundo).",
    },
    {
        "_id"       : "593e9297e17df20c34sa237d42",
        "index"     : 4,
        "picture"   : Image5,
        "icon"      : Icon5,
        "iconPosition" : "center",
        "text"      : "Experiencia curricular.",
    },
    {
        "_id"       : "593ea13327e17df20c4a237d42",
        "index"     : 5,
        "picture"   : Image6,
        "icon"      : Icon6,
        "iconPosition" : "left",
        "text"      : "Sensibilización y empatía.",
    },
    {
        "_id"       : "593e9297eg17df55A4a237d42",
        "index"     : 6,
        "picture"   : Image7,
        "icon"      : Icon7,
        "iconPosition" : "left",
        "text"      : "Nuevos amigos/una nueva familia.",
    }
    ]
}

