import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogDiv, InnerCard, CloseButton, TitleDiv, TitleText, FormContainer, SubtitleText } from './ModalCupoLlenoStyles';

const ModalCupoLleno = (props) => {
    const { open, setOpen } = props;
    return <Dialog maxWidth={false} open={open} PaperComponent={DialogDiv} onClose={() => setOpen(false)}>
        <InnerCard>
            <TitleDiv>
                <TitleText>Este viaje tuvo mucho éxito y su cupo ya está lleno...</TitleText>
                <SubtitleText>¡Pero te invitamos a dejar tus datos para que podamos enviarte las siguientes fechas de este viaje!</SubtitleText>
            </TitleDiv>
            <FormContainer>
                <input name="name" placeholder="Nombre completo"/>
                <input name="email" placeholder="E-mail"/>
                <button>Enviar</button>
            </FormContainer>
        </InnerCard>
        <CloseButton onClick={() => { setOpen(false) }} />
    </Dialog>
};

export default ModalCupoLleno;