
import React, { useState } from "react";
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import TitleField from '../../components/TitleField/TitleField';
import IconButton from '@material-ui/core/IconButton';
import Graph from '../../components/Graph/Graph';
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useStyles, gridGeneralStyle} from './CaminoRecorridoStyles';
import data from './data';
import NavBar from "../../components/Navbar.js";
import Contacto from "../../components/Contacto.js";
import './../../AppStyles.css';
import './CaminoRecorrido.css';
import { makeStyles } from '@material-ui/core/styles';

import ButtonNext from '../../images/iconos/botonSiguienteActivo.png';
import ButtonBack from '../../images/iconos/botonAtrasActivo.png';

const buttonStyles = makeStyles({
    root: {
        padding: 0,
        marginLeft: "20px",
        marginTop: "20px",
    },
    label: {
        padding: 0, 
    },
});

const QuelityLibraryScreen = (props) => {
    //---------------------------------------------------------------------------
    // Text Constants
    //---------------------------------------------------------------------------
    const TitleText = (<p> Esta es nuestra historia. <br/> Está llena de aventuras, abrazos <br/> y evolución. </p>);
    const BottomText = (<p> Únete a la travesía de <br/> querer cambiar el mundo </p>);
    const isMobile = useMediaQuery("(max-width:760px)");
    const buttonClasses = buttonStyles();

    //---------------------------------------------------------------------------
    // useState Hook
    //---------------------------------------------------------------------------
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const [sliderData] = useState({
            properties: data?.properties || [],
            property: data?.properties[selectedImageIndex + 1 < (data?.properties?.length) ? selectedImageIndex + 1 : selectedImageIndex ] || [],
    });

    const [selectedImageYear, setSelectedImageYear] = useState(
        parseInt(sliderData?.property?.date?.trim().substr(sliderData?.property?.date?.length - 4) || 0)
    );

    //---------------------------------------------------------------------------
    // Functions
    //---------------------------------------------------------------------------
    const nextProperty = () => {
        let newIndex = selectedImageIndex + 1;
        
        if(newIndex < (sliderData?.properties?.length)){
            updateYearIndex(newIndex);
            setSelectedImageIndex(newIndex);
        }else if (newIndex === ((sliderData?.properties?.length))){
            newIndex = 0;
            updateYearIndex(newIndex);
            setSelectedImageIndex(newIndex);
        }
    }

    const prevProperty = () => {
        const newIndex = selectedImageIndex - 1;
        
        if(newIndex >= 0){
            updateYearIndex(newIndex);
            setSelectedImageIndex(newIndex);
        }
    }
    
    const updateYearIndex = (newIndex) => {
        let indexToBeSet = newIndex + 1;
        if (window.matchMedia("(max-width: 760px)").matches){
            indexToBeSet = indexToBeSet - 1;
        }
        if((newIndex + 1) < (sliderData?.properties?.length))
            setSelectedImageYear(getYearWithImageIndex(indexToBeSet));
    }

    const getToYear = (desiredYear) => {
        if(desiredYear !== selectedImageYear){
            if(desiredYear < selectedImageYear){
                let tempIndex = selectedImageIndex;
                while(getYearWithImageIndex(tempIndex) !== -1 && getYearWithImageIndex(tempIndex) !== parseInt(desiredYear)){
                    tempIndex--;
                }

                if(validateIndex(tempIndex-1))
                    tempIndex = tempIndex-1;
                if(validateIndex(tempIndex)){
                    updateYearIndex(tempIndex);
                    setSelectedImageIndex(tempIndex);
                }
                
            }
            else {
                let tempIndex = selectedImageIndex + 1;
                while(getYearWithImageIndex(tempIndex) !== -1 && getYearWithImageIndex(tempIndex) !== parseInt(desiredYear)){
                    tempIndex++;
                }

                if(validateIndex(tempIndex-1))
                    tempIndex = tempIndex-1;
                if(validateIndex(tempIndex)){
                    updateYearIndex(tempIndex);
                    setSelectedImageIndex(tempIndex);
                }
            }
        }
    }

    const validateIndex = (index) => {
        return index  < (sliderData?.properties?.length) && index >= 0;
    }

    const getYearWithImageIndex = (index) => {
        if(validateIndex(index)){
            return parseInt(sliderData?.properties[index].date?.trim().substr(sliderData?.properties[index].date?.length - 4) || 0);
        }
        return -1;
    }

    return <div className="CaminoRecorrido">
                <NavBar isMobile={isMobile} showCovid={true} fillColor={"#1DCAD3"} isHome={true} />
                <div className="topTitle">
                    <TitleField  text={TitleText}/> 
                </div>
                <ImageSlider 
                    properties={sliderData.properties} 
                    currIndex={selectedImageIndex} 
                    nextIndex={nextProperty}
                    prevIndex={prevProperty}/> 
                <Grid
                style={gridGeneralStyle.Grid}
                className={useStyles.root} 
                container
                justify="center"
                >
                    <Grid item className="elementHiddenMobile">
                        <div className="leftArrowGraph">
                            <IconButton 
                                aria-label="back" 
                                classes={{
                                    root: buttonClasses.root, // class name, e.g. `classes-nesting-root-x`
                                    label: buttonClasses.label, // class name, e.g. `classes-nesting-label-x`
                                }}
                                onClick={prevProperty} > 
                                <img
                                    src={ButtonBack}
                                    alt="Back Button"
                                    style={{ height: '60px' }}
                                />
                            </IconButton>
                        </div>
                    </Grid>
                    <Grid item >
                        <Graph 
                            selectedYear={selectedImageYear}
                            selectYear={getToYear}
                        />
                    </Grid>
                    <Grid item className="elementHiddenMobile">
                        <div className="rightArrowGraph">
                            <IconButton 
                                aria-label="next" 
                                classes={{
                                    root: buttonClasses.root, // class name, e.g. `classes-nesting-root-x`
                                    label: buttonClasses.label, // class name, e.g. `classes-nesting-label-x`
                                }}
                                onClick={nextProperty}> 
                                <img
                                    src={ButtonNext}
                                    alt="Back Button"
                                    style={{ height: '60px' }}
                                />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                className={useStyles.root} 
                container
                justify="center"
                >
                    <Grid item xs={12}>
                        <TitleField text={BottomText} color="secondary"/> 
                    </Grid>
                    <Grid item >
                        <button 
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href='http://bit.ly/Viajes2021TDA';
                                }}
                            className="WideButton PrimaryColor Informacion">Conoce más</button>
                    </Grid>
                    <Grid 
                        item xs={12}
                        container
                        id="contactoBlock"
                        className="panel">
                        <Contacto isMobile={isMobile}/>
                    </Grid>
                </Grid>
            </div>;
};

export default QuelityLibraryScreen;
