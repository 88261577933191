//src="https://drive.google.com/file/d/12szE1MpuIdi3TqvQcIQ0BWcR1GW4XPB3/preview"

import React, { Component } from 'react';
import Image from '../../images/grafica/CaminoRecorrido_LineaDelTiempo.png';
import './Graph.css';
import '../../Screens/CaminoRecorrido/CaminoRecorrido.css';
import '../../AppStyles.css';

// class component
class Graph extends Component {
    render() {
        const Years_Data = [
            { year: "2011", height: "bottom", row: "r1" },
            { year: "2013", height: "bottom", row: "r3" },
            { year: "2015", height: "bottom", row: "r5" },
            { year: "2017", height: "bottom", row: "r7" },
            { year: "2019", height: "bottom", row: "r9" },
            { year: "2012", height: "middle", row: "r2" },
            { year: "2014", height: "top", row: "r4" },
            { year: "2016", height: "top", row: "r6" },
            { year: "2018", height: "top", row: "r8" },
            { year: "2020", height: "top", row: "r10" },
        ];

        const yearsDivs = Years_Data.map( data =>{
            let classDiv = [data.height, data.row, "Text-Container", "Informacion", "ThemeColor"];
            if(this.props.selectedYear === parseInt(data.year))
                classDiv.push("current-card");
            return <div onClick={() => this.props.selectYear(data.year)} className={classDiv.join(" ")} key={data.year} >{data.year}</div>
        });

        return (
        <div className="Container">
            <img className="Graph-Image" src={Image} alt="GraphDriveImage"/>
            {yearsDivs}
        </div>
        );
    }
}

export default Graph;
