import React from "react";

import Mision from '../../images/unPocoDeNosotros/MisionVision/Mision_Icono.png';
import Vision from '../../images/unPocoDeNosotros/MisionVision/Vision_Icono.png';

import IAlegria from '../../images/unPocoDeNosotros/Valores/Alegria_Icono.png';
import IBienestar from '../../images/unPocoDeNosotros/Valores/Bienestar_Icono.png';
import ICongruencia from '../../images/unPocoDeNosotros/Valores/Congruencia_Icono.png';
import IDiversidad from '../../images/unPocoDeNosotros/Valores/Diversidad_Icono.png';

import BannerImageImport from '../../images/unPocoDeNosotros/TierraDeArtistas_Banner.png';
import BottomImageImport from '../../images/unPocoDeNosotros/7Cosas.png';

import ImagePorque from '../../images/unPocoDeNosotros/Pasos/1Porque_Titulo.png';
import ImageComo from '../../images/unPocoDeNosotros/Pasos/2Como_Titulo.png';
import ImageQue from '../../images/unPocoDeNosotros/Pasos/3Que_Titulo.png';

import ArtePaso from '../../images/unPocoDeNosotros/SieteCosas/Arte_Icono.png';
import AsistencialismoPaso  from '../../images/unPocoDeNosotros/SieteCosas/Asistencialismo_Icono.png';
import DesarrolloPaso  from '../../images/unPocoDeNosotros/SieteCosas/DesarrolloHumano_Icono.png';
import MexicoPaso  from '../../images/unPocoDeNosotros/SieteCosas/Mexico_Icono.png';
import PagoPaso  from '../../images/unPocoDeNosotros/SieteCosas/PagoIntegro_Icono.png';
import ProgramasPaso  from '../../images/unPocoDeNosotros/SieteCosas/ProgramasIntegrales_Icono.png';
import ProyectosPaso  from '../../images/unPocoDeNosotros/SieteCosas/ProyectosPersonalizados_Icono.png';

//EXPORTS --------

export const NosotrosBanner = BannerImageImport;
export const NosotrosBottom = BottomImageImport;

export const PasosNosotros = [
    {
        "_id"       : "593e9297e17df20c4a23742",
        "picture"   : ImagePorque,
        "text"      : <p>A Tierra de Artistas nos duele la brecha social   que existe en México y el mundo; queremos   cambiar los patrones y estructuras sociales   de pobreza y desigualdad, transformar el   paradigma de "sólo existo yo" por un   "existimos nosotros".</p>,
        "title"     : <p>Colaboración</p>,
        "orientation" : "left",
    }, 
    {
        "_id"       : "5939297e17df20c4a237d42",
        "picture"   : ImageComo,
        "text"      : <p>Usando el arte, conectamos a personas con   ganas de ayudar y a comunidades marginadas,   en conjunto desarrollamos soluciones en pro   del desarrollo económico de la comunidad y   empoderamos a niños a convertirse en agentes de cambio.</p>,
        "title"     : <p>Co-creación</p>,
        "orientation" : "right",
    },
    {
        "_id"       : "593e9297e17df2c4a237d42",
        "picture"   : ImageQue,
        "text"      : <p>Ofrecemos experiencias comunitarias alrededor   del mundo para realizar proyectos de alto   impacto social, conocer nuevas culturas y   vivir una transformación personal.</p>,
        "title"     : <p>Sentido de <br/> pertenencia</p>,
        "orientation" : "left",  
    },
];

export const nuestrosValores = [
    {
        "id"       : "593e9297e17df20c4a237d42",
        "icon"      : IBienestar,
        "title"     : <p>Bienestar</p>,
        "text"      : <p>Antes de ir afuera <br/> ve adentro. </p>,
    },
    {
        "id"       : "593e9297e17sdf20c4a237d4j2",
        "icon"      : ICongruencia,
        "title"     : <p>Congruencia</p>,
        "text"      : <p>Sé transparente.</p>,
    },
    {
        "id"       : "5931e9297557df20c4a2h37d42",
        "icon"      : IAlegria,
        "title"     : <p>Alegria</p>,
        "text"      : <p>Mantente en contacto <br/> con tu niño(a) interior. </p>,
    },
    {
        "id"       : "593e9297e17df20cf3a2375d42",
        "icon"      : IDiversidad,
        "title"     : <p>Diversidad</p>,
        "text"      : <p>Viva la libertad <br/> y la inclusión. </p>,
    },
]

export const MisionVision = [
    {
        "_id"       : "593e9297e17df2s0cf3a2375d42",
        "picture"      : Mision,
        "title"     : <p>Misión</p>,
        "orientation" : "right",
        "text"      : <p>Diseñar experiencias comunitarias donde conectemos a personas con talentos y ganas de ayudar con comunidades marginadas o urbanas en polígonos de pobreza y
                        en conjunto desarrollar soluciones en pro del desarrollo económico de la comunidad. Sensibilizar y empoderar a niños y jóvenes a convertirse en agentes de cambio,
                        líderes empáticos ante las problemáticas globales. Todo, a través del arte y la innovación social. </p>,
    },
    {
        "_id"       : "593e9297e17df20cf3a23a75d42",
        "picture"      : Vision,
        "title"     : <p>Visión</p>,
        "orientation" : "left",
        "text"      : <p>Transformar patrones y estructuras sociales para reducir la pobreza y la desigualdad. Generar la mayor cantidad posible de empleos decentes en comunidades vulnerables del mundo. Capacitar a emprendedores sociales e
                        impulsarlos para resolver problemáticas globales a través de su emprendimiento. Construir una sociedad donde todos somos agentes de cambio. </p>,
    },
]

export const MisionImage = Mision;
export const VisionImage = Vision;


export const cosasQueNosDistinguenData = [
    {
        "id"       : "593e9297e17df20c4a237d42",
        "icon"      : ArtePaso,
        "index"     : 0,
        "title"     : <p>Arte</p>,
        "text"      : <p>Lenguaje que no conoce barreras ni fronteras.  <br/>   El arte es nuestra herramienta para acercarnos a las personas y provocar comunidad.     En nuestros proyectos se generan piezas de arte como forma de expresión, por ejemplo:    
                        fotografías, poesías, canciones, piezas de baile, etc. Creemos que todos somos artistas     y no es necesario ser artista para participar. </p>,
    },
    {
        "id"       : "593e9297e17sdf20c4a237d4j2",
        "icon"      : MexicoPaso,
        "index"     : 1,
        "title"     : <p>Impulso de proyectos en México <br/> como prioridad </p>,
        "text"      : <p>La mayor parte de nuestros proyectos son nacionales porque queremos impulsar y     transformar principalmente a nuestro país, sin embargo en todos lados se necesita ayuda.     Siguiendo el flujo natural de la vida, poco a poco Tierra de Artistas irá creciendo su red de    
                        afiliados por el mundo pero nunca perderemos nuestro foco: México. </p>,
    },
    {
        "id"       : "5931e9297557df20c4a2h37d42",
        "icon"      : PagoPaso,
        "index"     : 2,
        "title"     : <p>Pago íntegro a las comunidades</p>,
        "text"      : <p>Creemos fielmente que se les debe de pagar de una manera justa a las personas de     la comunidad que trabajan duro para ofrecernos hospedaje y comidas, así como     a los artesanos y agricultores que nos comparten su conocimiento en talleres     
                        o expediciones culturales.  </p>,
    },
    {
        "id"       : "593e9297e17df20cf3a2375d42",
        "icon"      : ProgramasPaso,
        "index"     : 3,
        "title"     : <p>Diseño de programas integrales</p>,
        "text"      : <p>Diseñamos los programas sociales desde cero. <br/>    Nuestro equipo de expertos realiza un diagnóstico comunitario y bajo metogologías     de diseño y resolución de problemas se diseñan soluciones a corto, mediano y largo    
                        plazo junto con la comunidad. Buscamos que los proyectos incidan en problemas     locales que la comunidad misma quiera y pueda solucionar haciendo uso de sus     habilidades y con nuestra guía, ellos mismos los lleven a cabo. </p>,
    },
    {
        "id"       : "593e297e17sdf20c4a237d4j2",
        "icon"      : AsistencialismoPaso,
        "index"     : 4,
        "title"     : <p>Creemos que el asistencialismo no es la solución </p>,
        "text"      : <p>Buscamos que nuestros proyectos sean co-creación y colaboración con las     comunidades a través de herramientas de autogestión que compartimos con ellas.     Uno de nuestros principios es NO decirle a la comunidad lo que necesitan    
                        y hacerlo por ellos. </p>,
    },
    {
        "id"       : "5931e9297557df20c42h37d42",
        "icon"      : DesarrolloPaso,
        "index"     : 5,
        "title"     : <p>Somos expertos en desarrollo humano y crecimiento personal</p>,
        "text"      : <p>Realizamos sesiones guiadas de desarrollo humano para que los voluntarios vivan una     experiencia transformadora. Les enseñamos herramientas de introspección y autoconocimiento para impulsar en ellos su máximo potencial como personas </p>,
    },
    {
        "id"       : "593e9297e17df20cf3a2375d2",
        "icon"      : ProyectosPaso,
        "index"     : 6,
        "title"     : <p>Proyectos personalizables</p>,
        "text"      : <p>Nuestros paquetes base constan en viajes de voluntariado, programas de RSE y     actividades educativas que se ajustan a las necesidades y gustos de nuestros clientes     y de las comunidades donde participamos. </p>,
    },
]

