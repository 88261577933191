import styled from 'styled-components';

export const InnovacionContainer = styled.div`
  height: auto;
  /* INNOVACION INICIO */
  .innovacionBlock {
    background-color: #fff;
    height: auto;
  }
  .innovacionTop {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .innovacionImage {
    width: 50%;
    height: 100%;
    max-height: 100%;
  }
  .innovacionInfoContainer {
    width: 50%;
    background-color: #00758d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: 42px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: justify;
      color: #ffffff;
      max-width: 81%;
      margin: 10px 10px;
    }
    p {
      font-size: 17px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 22px;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      max-width: 75%;
    }

    button {
      width: 50%;
      background-color: #1DCAD3;
      font-size: 20px;
      font-weight: bold;
      padding: 25px 0px;
      color: #ffffff;
      border: none;
      margin-top: 20px;
      letter-spacing: 1.2;
    }
    @media (min-width: 1920px) {
      p {
        font-size: 22px;
      }
      h2 {
        font-size: 45px;
      }
    }
    @media (max-width: 1030px) {
      h2 {
        font-size: 30px;
      }
      p {
        font-size: 15px;
      }
      button {
        padding: 10px 0px;
      }
    }
  }

  .innovacionBottom {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
  }

  .noResponsive {
    display: flex;
  }

  .responsive {
    display: none;
  }

  @media (min-width: 300px) and (max-width: 570px) {
    .innovacionInfoContainer {
      h2 {
        font-size: 24px !important;
        margin: 20px 0;
        width: 90%;
        max-width: 70%;
        text-align: center;
      }

      p {
        font-size: 16px;
        margin: 10px 0;
        width: 75%;
        max-width: 70% !important;
        line-height: 1;
      }

      button {
        padding: 14px !important;
        font-size: 12px !important;
      }
    }
  }

  @media (max-width: 760px) {
    margin-top: 10px;

    .innovacionTop {
      flex-direction: column;
    }

    .innovacionBottom {
      flex-direction: column;
    }

    .innovacionImage {
      width: 100%;
      height: auto;
    }

    .innovacionInfoContainer {
      width: 100%;
      height: auto;
      padding: 0;
      text-align: start;

      h2 {
        font-size: 30px;
        margin: 20px 0;
        max-width: 90%;
        text-align: center;
      }

      p {
        font-size: 16px;
        margin: 10px 0;
        width: 75%;
        max-width: 90%;
        line-height:1;
      }

      button {
        background-color: #1DCAD3;
        font-size: 14px;
        margin: 30px 0;
        padding: 20px;
        width: 40%;
      }
    }

    .noResponsive {
      display: none;
    }

    .responsive {
      display: flex;
    }
  }

  /* INNOVACION FIN */
`;
