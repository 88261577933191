import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { BlogContainer } from "./styledBlog.js";
import ModalEnConstruccion from "./ModalEnConstruccion/ModalEnConstruccion.js";
import { formatDate } from "../utils";

const Blog = (props) => {
  const [construccionModal, setConstruccionModal] = useState(false);
  return (
    <BlogContainer>
      <Grid item xs={12} className="blogTop">
        {props.posts.map((post, index) => {
          const imgUrl = props.isMobile ? post.mobileImage : post.webImage;
          return (
            <>
              <img
                src={imgUrl}
                className="blogImage"
                alt="Consultoria empresarial"
              />
              <div className="blogPostInfo">
                <h3>{formatDate(post.date)}</h3>
                <h2>{post.title}</h2>
                <p>{post.post}</p>
                <a href="index.html" onClick={() => setConstruccionModal(true)}>
                  Leer más
                </a>
              </div>
            </>
          );
        })}
        <button id="masNoticias" onClick={() => setConstruccionModal(true)}>
          Más noticias
        </button>
      </Grid>
      <ModalEnConstruccion
        open={construccionModal}
        setOpen={setConstruccionModal}
      />
    </BlogContainer>
  );
};
export default Blog;
