import React from 'react';
import Grid from '@material-ui/core/Grid';
import HuellaCarousel from '../HuellaCarousel';

export default function DejarHuella({ countries }) {
  return (
    <Grid container className="panel" id="huellaBlock" direction="row">
      <h2 className="sectionTitle">¿Dónde quieres dejar huella?</h2>
      <Grid item xs={12} style={{ margin: 'auto', marginTop: '0px' }}>
        <Grid
          container
          direction="row"
          justify="center"
          style={{ width: '100%' }}
        >
          {/* <HuellaStepper expeditions={expeditions.concat(pastExpeditions)} /> */}
          <HuellaCarousel expeditions={countries} />
        </Grid>
      </Grid>
    </Grid>
  );
}
