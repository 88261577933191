import React, { Component } from 'react';
import './ImageSlider.css';
import Card from './ImageCard/ImageCard';

// class component
class ImageSlider extends Component {

    constructor(props){
        super(props);
        this.state = {
            initialClientX: 0,
            finalClientX: 0,
            dragging: false,
        }
    }

    handleTouchStart = (event) => {
        this.setState({
            initialClientX: event.nativeEvent.touches[0].clientX
        });
    }

    handleTouchMove = (event) => {
        this.setState({
            finalClientX: event.nativeEvent.touches[0].clientX
        });
    }

    handleTouchEnd = () => {
        if ((this.state.finalClientX - this.state.initialClientX) > 100) {
            this.props.prevIndex();
        }else if ((this.state.finalClientX - this.state.initialClientX) < -100){
            this.props.nextIndex();
        }
    
        this.setState({
            initialClientX: 0,
            finalClientX: 0,
        });
    }

    handleMouseStart = (event) => {
        if (event.button !== 0) return

        this.setState({
            dragging: true,
            initialClientX: event.screenX,
        });
    }

    handleMouseMove = (event) => {
        if(!this.state.dragging) return;

        this.setState({
            finalClientX: event.screenX,
        });
    }

    handleMouseEnd = () => {
        if(!this.state.dragging) return;

        if ((this.state.finalClientX - this.state.initialClientX) > 10) {
            this.props.prevIndex();
        }else if ((this.state.finalClientX - this.state.initialClientX) < -10){
            this.props.nextIndex();
        }
    
        this.setState({
            dragging: false,
            initialClientX: 0,
            finalClientX: 0,
        });
    }

    render() {
        const {properties, currIndex} = this.props;
        const property = properties[currIndex];

        return (
        <div>

            {/* <button 
            onClick={() => this.nextProperty()} 
            disabled={property?.index === data.properties.length-1}
            >Next</button>
            <button 
            onClick={() => this.prevProperty()} 
            disabled={property?.index === 0}
            >Prev</button> */}

            <div className="page" 
                onTouchMove={this.handleTouchMove} onTouchStart={this.handleTouchStart} onTouchEnd={this.handleTouchEnd}
                onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseStart} onMouseUp={this.handleMouseEnd}>
                <div className={`cards-slider active-slide-${property?.index}`}>
                    <div className="cards-slider-wrapper" style={{
                    'transform': `translateX(-${(property?.index )*(100/properties.length)}%)`
                    }}>
                    {
                        properties.map(property => <Card key={property._id} property={property} />)
                    }
                    </div>
                </div>

            </div>
        </div>
        );
    }
}

export default ImageSlider;
