import React from 'react';
import Grid from '@material-ui/core/Grid';

export default function ProximosViajes({ proximosViajes }) {
  return (
    <Grid container className="panel" id="proximosViajesBlock" direction="row">
      <h1 className="sectionTitle">Nuestros próximos proyectos</h1>
      <Grid item xs={11} style={{ margin: 'auto', marginTop: '0px' }}>
        <Grid
          container
          direction="row"
          justify="center"
          spacing={2}
          style={{ width: '100%', margin: '50px 0px 140px 0px' }}
        >
          {proximosViajes}
        </Grid>
      </Grid>
    </Grid>
  );
}
