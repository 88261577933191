import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import ModalEnConstruccion from "../ModalEnConstruccion/ModalEnConstruccion";
import ModalCupoLleno from "../ModalCupoLleno/ModalCupoLleno";
import ButtonUp from "../../images/iconos/botonArribaActivo.png";
import ButtonDown from "../../images/iconos/botonAbajoActivo.png";
// UTILS
import { createDateRange } from "../../utils";

function ProximoViajeCarousel({ place, imgSrc }) {
  return (
    <img
      styles={{ maxHeight: "1rem" }}
      src={imgSrc}
      alt={place}
    />
  );
}

function OpenButton({ setOpen, isOpen }) {
  return (
    <IconButton onClick={() => setOpen(!isOpen)}>
      {!isOpen ? (
        <img
          src={ButtonUp}
          style={{ height: "20px" }}
          alt="Icono de leer menos"
        />
      ) : (
        <img
          src={ButtonDown}
          style={{ height: "20px" }}
          alt="Icono de leer más"
        />
      )}
    </IconButton>
  );
}

function ProximoViajeCard(props) {
  const {
    place,
    start,
    end,
    soldOut,
    title,
    className,
    description,
    isOpen,
    setIsOpen,
    handleOpenModal,
  } = props;

  const fechas = createDateRange(start, end);

  return (
    <div className={className}>
      <OpenButton setOpen={setIsOpen} isOpen={isOpen} />
      <h2 className="place">{place}</h2>
      <p className="covid">{fechas.toUpperCase()}</p>
      {isOpen && (
        <div className="descriptionContainer">
          <p className="title">{title}</p>
          <p className="description">{description}</p>
          {soldOut ?
          <div className="cta" onClick={() => handleOpenModal(soldOut)}>
             "Cupo Lleno" 
          </div>
             : (
              <a
                href="https://bit.ly/aplicatravelteer"
                target="_blank"
                className="cta"
                style={{
                  backgroundColor: "#F9F7F1",
                  color: "#1DCAD3",
                }}
              >
                Me interesa
              </a>
              )
             }
        </div>
      )}
    </div>
  );
}

const StyledProximoViajeCard = styled(ProximoViajeCard)`
  color: ${(props) => (props.soldOut ? "#FFF" : "#1DCAD3")};
  transition: all 0.3s ease-out;
  height: ${(props) => (props.isOpen ? "380px" : "220px")};
  margin-top: ${(props) => (props.isOpen ? "-160px" : "0px")};
  z-index: 2;
  width: 100%;
  background-color: ${(props) => (props.soldOut ? "#f0b500" : "#F9F7F1")};
  padding: 10px 40px;

  .control-dots ul {
    top: 0 !important;
  }

  .place {
    font-size: 2.2em;
    margin: 10px 10px;
  }

  .covid {
    color: ${(props) => (props.soldOut ? "#F9F7F1" : "#f0b500")};
    font-size: 1em;
    margin: 5px 0 40px 10px;
  }

  .descriptionContainer {
    color: ${(props) => (props.soldOut ? "#FFF" : "#000")};
    font-size: 1em;
    margin-left: 10px;
  }

  .title {
    font-weight: bold;
    margin: 2px 0;
  }

  .description {
    margin: 2px 0;
  }

  .cta {
    margin: 40px 0;
    cursor: pointer;
    text-decoration: underline;
    font-style: italic;
    font-size: 1.2em;
    color: ${(props) => (props.soldOut ? "#FFF" : "#1DCAD3")};
  }
`;

export default function MobileProximoViajes({ expeditions }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [constructionModal, setConstructionModal] = useState(false);

  const handleChangeInSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const handleOpenModal = (soldOut) => {
    if (soldOut) {
      setModalOpen(true);
    } else {
      window.open("https://bit.ly/aplicatravelteer");
    }
  };

  return (
    <Grid container className="panel" direction="row">
      <h1 className="sectionTitle carrouselSection">
        Nuestros próximos proyectos
      </h1>
      <Carousel
        showStatus={false}
        showThumbs={false}
        dynamicHeight={false}
        onChange={handleChangeInSlide}
        showArrows={false}
        showIndicators={false}
        selectedItem={currentSlide}
      >
        {expeditions &&
          expeditions.map((viaje) => (
            <ProximoViajeCarousel place={viaje.place} imgSrc={viaje.imgSrc} />
          ))}
      </Carousel>
      <StyledProximoViajeCard
        {...expeditions[currentSlide]}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleOpenModal={handleOpenModal}
      />
      <ModalCupoLleno open={modalOpen} setOpen={setModalOpen} />
      <ModalEnConstruccion
        open={constructionModal}
        setOpen={setConstructionModal}
      />
    </Grid>
  );
}
