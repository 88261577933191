import Image1 from '../../images/acontecimientos/CaminoRecorrido_Foto1.png';
import Image2 from '../../images/acontecimientos/CaminoRecorrido_Foto2.png';
import Image3 from '../../images/acontecimientos/CaminoRecorrido_Foto3.png';
import Image4 from '../../images/acontecimientos/CaminoRecorrido_Foto4.png';
import Image5 from '../../images/acontecimientos/CaminoRecorrido_Foto5.png';
import Image6 from '../../images/acontecimientos/CaminoRecorrido_Foto6.png';
import Image7 from '../../images/acontecimientos/CaminoRecorrido_Foto7.png';
import Image8 from '../../images/acontecimientos/CaminoRecorrido_Foto8.png';
import Image9 from '../../images/acontecimientos/CaminoRecorrido_Foto9.png';
import Image10 from '../../images/acontecimientos/CaminoRecorrido_Foto10.png';
import Image12 from '../../images/acontecimientos/CaminoRecorrido_Foto12.png';
import Image13 from '../../images/acontecimientos/CaminoRecorrido_Foto13.png';
import Image14 from '../../images/acontecimientos/CaminoRecorrido_Foto14.png';
import Image15 from '../../images/acontecimientos/CaminoRecorrido_Foto15.png';
import Image16 from '../../images/acontecimientos/CaminoRecorrido_Foto16.png';
import Image17 from '../../images/acontecimientos/CaminoRecorrido_Foto17.png';
import Image18 from '../../images/acontecimientos/CaminoRecorrido_Foto18.png';
import Image19 from '../../images/acontecimientos/CaminoRecorrido_Foto19.png';
import Image20 from '../../images/acontecimientos/CaminoRecorrido_Foto20.png';
import Image21 from '../../images/acontecimientos/CaminoRecorrido_Foto21.png';
import Image22 from '../../images/acontecimientos/CaminoRecorrido_Foto22.png';
import Image23 from '../../images/acontecimientos/CaminoRecorrido_Foto23.png';
import Image24 from '../../images/acontecimientos/CaminoRecorrido_Foto24.png';
import Image25 from '../../images/acontecimientos/CaminoRecorrido_Foto25.png';


const data = {
    "properties": [
    {
        "_id": "593e9297e17df20c4a237d42",
        "index": 0,
        "picture": Image1,
        "title": "Donde todo comenzó",
        "text": "Frida termina la universidad, decide realizar un viaje a la Amazonía peruana y durante este viaje descubre su propósito de vida: darle voz a quienes no la tienen.",
        "date": "Jun 2011",
    },
    {
        "_id": "593e9297e17sdf20c4a237d42",
        "index": 1,
        "picture": Image2,
        "title": "Fundación",
        "text": "Frida regresa a México, reune a un grupo de colaboradores y funda Tierra de Artistas como Asociación Civil Comienza con el primer proyecto de arte y fotografía Una mirada a mi País.",
        "date": "Sep 2011",
    },
    {
        "_id": "593e9297557df20c4a237d42",
        "index": 2,
        "picture": Image3,
        "title": "Premio UVM",
        "text": "Frida gana un concurso a nivel nacional con Tierra de Artistas y el proyecto Una Mirada a mi País. Gracias al premio, el proyecto comienza a replicarse en comunidades de Nuevo León.",
        "date": "Feb 2012",
    },
    {
        "_id": "593e9297e17df20c3a237d42",
        "index": 3,
        "picture": Image4,
        "title": "Primer viaje",
        "text": "Durante el verano, Tierra de Artistas hace su primer viaje de voluntariado a Puebla con 15 voluntarios estudiantes del Tec de Monterrey. Llevan a cabo el proyecto de arte y fotografía Una mirada a mi País",
        "date": "Jul 2012",
    },
    {
        "_id": "593e1297e17df20c4a237d42",
        "index": 4,
        "picture": Image5,
        "title": "Alma en Movimiento",
        "text": "Tierra de Artistas desarrolla un proyecto para niñas y mujeres con el objetuvo de empoderarlas, mejorar el autoestima y hacer introspección a través de la danza, música y arteterapia.",
        "date": "2013",
    },
    {
        "_id": "593e9297e17df20A4a237d42",
        "index": 5,
        "picture": Image6,
        "title": "Voces Libres",
        "text": "Nace un nuevo proyecto para que jóvenes expresen sus sueños, miedos e ideas de manera responsable, a través de la escritura creativa y poesía en voz alta.",
        "date": "2014",
    },
    {
        "_id": "593e9297e17dfP0c4a237d42",
        "index": 6,
        "picture": Image7,
        "title": "Uniendo escuelas con causa",
        "text": "Se desarrolla un programa de arte para el Instituto Brillamont. El proyecto se realiza en conjunto con una escuela pública; concluyen con una exposición final de arte en el Museo El Centenario. Los niños de ambas escuelas generan lazos de amistad.",
        "date": "2015",
    },
    {
        "_id": "593e9297e17dSD20c4a237d42",
        "index": 7,
        "picture": Image8,
        "title": "Voluntariado en Oaxaca",
        "text": "Tierra de Artistas viaja a Oaxaca para hacer diagnóstico comunitario y pintar el primer mural. Nace la propuesta del macro proyecto “Recorrido de Murales” y “Hostal Casa Tía Eva”.",
        "date": "Mar 2016",
    },
    {
        "_id": "593e9297e17daf20c4a237d42",
        "index": 8,
        "picture": Image9,
        "title": "Muros que unen",
        "text": "Tierra de Artistas pinta su primer mural con los niños y niñas de Oaxaca, confirman que el arte rompe barreras y crea lazos. Desarrollan un nuevo proyecto para contar historias a través de murales.",
        "date": "May 2016",
    },
    {
        "_id": "593e9297e17di20c4a237d42",
        "index": 9,
        "picture": Image10,
        "title": "Fusión de talentos",
        "text": "Después de hacer un voluntariado en Oaxaca, Dani se une al equipo administrativo y se convierte en socia estratégica para darle un nuevo giro a la fundación. Su pasión y talentos complementaron la visión de Tierra de Artistas.",
        "date": "Ago 2016",
    },
    {
        "_id": "593e92975e17df20c4a237d42",
        "index": 10,
        "picture": Image12,
        "title": "Nuevo proyecto",
        "text": "Tierra de Artistas lleva a cabo su primer proyecto de construcción. Apoyando a Eva Morales y sus cuatro hijas a emprender; juntos se construye el mobiliario e infraestructura de un hostal así como el modelo de negocios.",
        "date": "Sep 2016",
    },
    {
        "_id": "593e9297e17df20c422a237d42",
        "index": 11,
        "picture": Image13,
        "title": "Recorrido de murales",
        "text": "Comienza el proyecto más importante de muralismo en Oaxaca. El objetivo, atraer turismo nacional e internacional a la comunidad de Arrazola a través de murales diseñados y pintados junto con la comunidad.",
        "date": "Sep 2016",
    },
    {
        "_id": "593eM9297e17df20c4a237d42",
        "index": 12,
        "picture": Image14,
        "title": "Voluntariado en BCS",
        "text": "Alianza con RED de Turismo Sustentable A.C. e inicio del proyecto por el corredor costero Sierra La Giganta para empoderar a las comunidades locales a través de proyectos de arquitectura social y diseño sustentable.",
        "date": "Mar 2017",
    },
    {
        "_id": "593e929M7e17df20c4a237d42",
        "index": 13,
        "picture": Image15,
        "title": "Primer voluntariado internacional",
        "text": "Tierra de Artistas reúne a 11 viajeros con causa y se aventuran a la Amazonía peruana, dónde comenzó todo. Se realiza el proyecto de fotografía “Una Mirada a mi País” con veinte niños y niñas de la comunidad.",
        "date": "Jul 2017",
    },
    {
        "_id": "593e9297e17df2M0c4a237d42",
        "index": 14,
        "picture": Image16,
        "title": "Voluntariado a Isla Taquile, Perú",
        "text": "Nace un nuevo viaje de voluntariado internacional, ahora en una isla en medio del Lago Titicaca; viajeros de México y España se unen a la aventura.",
        "date": "Jul 2018",
    },
    {
        "_id": "593e9297e17df20c4Ma237d42",
        "index": 15,
        "picture": Image17,
        "title": "Nos convertimos en Empresa Social",
        "text": "Buscando ser autogestivos, Tierra de Artistas experimenta una metamorfosis. Aplica un nuevo modelo de negocios donde la cadena de valor de nuestros viajes y consultorías está enfocada en crear un impacto positivo en comunidades vulnerables y el medio ambiente.",
        "date": "Ene 2019",
    },
    {
        "_id": "593e9297e17df20c4a23M7d42",
        "index": 16,
        "picture": Image18,
        "title": "Primer consultoría académica a nivel nacional",
        "text": "Se desarrolla programa de innovación social y sentido humano para los cinco campus ancla del Tecnológico de Monterrey. Se trabajó con más de 300 alumnos de nuevo ingreso en Ciudad de México, Estado de México, Monterrey, Guadalajara, Querétaro y Puebla.",
        "date": "Feb 2019",
    },
    {
        "_id": "593e9297e17df20c4a237dM42",
        "index": 17,
        "picture": Image19,
        "title": "Voluntariado en Yucatán",
        "text": "Alianza con U Yich Luum A.C. para un nuevo proyecto de arquitectura social y conservación de la cultura en la península. Comienza la construcción de un centro educativo agro-ecológico con el objetivo de preservar la cultura maya a través de talleres para y con la comunidad.",
        "date": "Jul 2019",
    },
    {
        "_id": "593e9297e17df20c4a237d4M2",
        "index": 18,
        "picture": Image20,
        "title": "Apertura de proyecto en Marruecos",
        "text": "Las socio-fundadoras Frida y Dani hacen un viaje a Marruecos para realizar un diagnóstico comunitario, generar alianzas estratégicas y abrir un nuevo proyecto.",
        "date": "Ago 2019",
    },
    {
        "_id": "593Ae9297e17df20c4a237d42",
        "index": 19,
        "picture": Image21,
        "title": "Apertura de proyecto en Kenia",
        "text": "Alianza con un orfanato al sureste de África para comenzar la etapa de diagnóstico comunitario e iniciar el proyecto, el cual que se convertirá en el destino más reciente para viajes de voluntariado internacional.",
        "date": "Ene 2020",
    },
    {
        "_id": "593e9297Ae17df20c4a237d42",
        "index": 20,
        "picture": Image22,
        "title": "Consultoría de impacto social a grandes empresas",
        "text": "Inician proyectos con dos importantes empresas constructoras que buscan ser socialmente responsables y hacer un diagnóstico comunitario: Grupo Acosta Verde y Emblem Capital.",
        "date": "Feb 2020",
    },
    {
        "_id": "593e9297e17df2A0c4a237d42",
        "index": 21,
        "picture": Image23,
        "title": "Crisis COVID 19",
        "text": "Inicia la pandemia por coronavirus y se tienen que posponer todos los viajes y proyectos. Nos enfocamos en sistematizar, re-diseñar procesos y encontrar nuevas líneas de acción para seguir ayudando, desde casa.",
        "date": "Mar 2020",
    },
    {
        "_id": "593e9297e17df20c4aA237d42",
        "index": 22,
        "picture": Image24,
        "title": "Rediseño de la marca",
        "text": "Para Tierra de Artistas, renovarse significa atreverse a recibir cosas nuevas. Les presentamos nuestra nueva imagen, diseñada por Pumpu Estudio.",
        "date": "2020",
    },
    {
        "_id": "593e9297e17df20c4a237Ad42",
        "index": 23,
        "picture": Image25,
        "title": "Podcast en Spotify",
        "text": "Nace nuestro podcast “La travesía de ser una Empresa Social” con el objetivo de compartir lo que sucede detrás de cámaras de ser una empresa social.",
        "date": "Abr 2020",
    },
    ]
}

export default data;