import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import * as moment from "moment/moment.js";
import firebase from "../../firebase/firebase.js";
import { LandingContainer } from "../../stylesheet.js";
import Navbar from "../../components/Navbar.js";
import { Element, Events } from "react-scroll";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//COMPONENTES CUSTOM
import ViajarConCausa from "../../components/ViajarConCausa";
import ProximoViaje from "../../components/ProximoViaje.js";
import Innovacion from "../../components/Innovacion.js";
import Blog from "../../components/Blog.js";
import Contacto from "../../components/Contacto.js";
import LandingBlock from "../../components/LandingBlock/LandingBlock.js";
import ModalRequisitos from "../../components/ModalRequisitos/ModalRequisitos.js";
import DejarHuella from "../../components/DejarHuella";
import MobileDejarHuella from "../../components/DejarHuella/MobileDejarHuella";
import ProximosViajes from "../../components/ProximosViajes";
import MobileProximoViajes from "../../components/ProximosViajes/MobileProximoViajes";
import VivenciaDeVoluntarios from "../../components/VivenciaDeVoluntarios";
import VivenciaDeVoluntariosMobile from "../../components/VivenciaDeVoluntarios/VivenciaDeVoluntariosMobile";
// UTILS
import { createDateRange } from "../../utils";

function App() {
  const [db, setDB] = useState(null);
  const [app, setApp] = useState(null);
  const [requirmentsModalOpen, setRequirementsModalOpen] = useState(false);
  const [expeditions, setExpeditions] = useState([]);
  const [, setPastExpeditions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [posts, setPosts] = useState([]);
  const [notification, setNotification] = useState(null);
  const isMobile = useMediaQuery("(max-width:760px)");

  useEffect(() => {
    //FETCH EXPEDITIONS
    if (!app) {
      try {
        setApp(firebase);
      } catch (error) {
        console.error("useEffect: ", error);
      }
    } else {
      setDB(app.firestore());
    }

    if (db) {
      db.collection("viajes")
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("No se encontraron viajes");
          } else {
            let tempRows = [];
            let tempPastRows = [];

            querySnapshot.forEach((doc) => {
              const startDate = doc?.data()?.start;
              if (!startDate || !moment().isBefore(startDate)) {
                tempPastRows.push({
                  _id: doc.id,
                  ...doc.data(),
                });
              } else {
                tempRows.push({
                  _id: doc.id,
                  ...doc.data(),
                });
              }
            });
            tempRows.sort(function (a, b) {
              if (a.start > b.start) {
                return 1;
              }
              if (b.start > a.start) {
                return -1;
              }
              return 0;
            });
            setExpeditions(tempRows);
            setPastExpeditions(tempPastRows);
          }
        });
      db.collection("países")
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("No se encontraron paises");
          } else {
            let paises = [];

            querySnapshot.forEach((doc) => {
              paises.push(doc?.data());
            });
            paises.sort(function (a, b) {
              if (a.order > b.order) {
                return 1;
              }
              if (b.order > a.order) {
                return -1;
              }
              return 0;
            });
            setCountries(paises);
          }
        });
      db.collection("blog")
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("No se encontraron Blog posts");
          } else {
            let posts = [];

            querySnapshot.forEach((doc) => {
              posts.push(doc?.data());
            });

            setPosts(posts);
          }
        });
      db.collection("notification")
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("No se encontró notificación");
          } else {
            let notif = [];

            querySnapshot.forEach((doc) => {
              notif.push(doc?.data());
            });

            if (notif.length) {
              setNotification(notif[0].message);
            } else {
              setNotification(false);
            }
          }
        });
    }
  }, [db, app]);

  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  });

  const proximosViajes = expeditions.slice(0, 4).map((viaje, index) => {
    const fechas = createDateRange(viaje.start, viaje.end);
    return (
      <ProximoViaje
        imgSrc={viaje.imgSrc}
        title={viaje.place}
        description={viaje.description}
        descriptionTitle={viaje.title}
        key={viaje.place + viaje.index}
        subtitle={fechas.toUpperCase()}
        soldOut={viaje.soldOut}
      />
    );
  });

  return (
    <LandingContainer>
      {/* CAROUSEL INICIO */}
      <LandingBlock isMobile={isMobile} />
      {/* NAVBAR */}
      <Navbar isMobile={isMobile} showCovid={true} notificationMessage={notification && notification} fillColor={"#1DCAD3"} isHome={true} />
      {/* SEGUNDA PANTALLA (FRASE) */}
      <Element name="secondScreen">
        <Grid container className="panel" id="secondBlock" direction="column">
          <h4 className="secondStepPhrase firstRow">
            Proyectos solidarios para conocer el mundo,
          </h4>
          <h4 className="secondStepPhrase">
            impulsar y empoderar a través del arte y el diseño
          </h4>
          <h4 className="secondStepPhrase">
            social a comunidades vulnerables, transformando
          </h4>
          <h4 className="secondStepPhrase lastRow">
            cooperativamente la vida de las personas.
          </h4>
          <img
            alt="Proyectos solidarios"
            src={`https://tierradeartistasimgs.s3.amazonaws.com/${
              isMobile
                ? "MobileImages/2.Ilustración esencia/Ilustracion_Esencia.png"
                : "images/Ilustraciones/Ilustracion_Esencia.png"
            }`}
          />
        </Grid>
      </Element>
      {isMobile ? <MobileDejarHuella /> : <DejarHuella countries={countries} />}
      {/* POR QUE VIAJAR CON CAUSA */}
      <ViajarConCausa />
      {/* APLICA PARA VIAJAR */}
      <Element name="aplicaScreen">
        <Grid container className="panel" id="aplicaBlock" direction="row">
          <Grid item xs={12} md={6} lg={5} className="aplicaTitleContainer">
            <h1 className="aplicaTitle">Aplica para viajar con nosotros</h1>
          </Grid>
          <Grid item xs={12} md={6} lg={5} className="aplicaListContainer">
            <ul className="aplicaList">
              <li className="aplicaListItem">
                <span className="customBullet">1</span>
                <p>Selecciona un destino y el proyecto que más te guste.</p>
              </li>

              <li className="aplicaListItem">
                <span className="customBullet">2</span>
                <p>Rellena el formulario y aplica gratuitamente.</p>
              </li>

              <li className="aplicaListItem">
                <span className="customBullet">3</span>
                <p>
                  Espera el correo de confirmación de uno de nuestros
                  coordinadores expertos.
                </p>
              </li>

              <li className="aplicaListItem">
                <span className="customBullet">4</span>
                <p>
                  Cumple con todos los <i>requisitos</i> para separar tu lugar.
                </p>
                <span
                  className="customBullet exclamationMark"
                  onClick={() => {
                    setRequirementsModalOpen(true);
                  }}
                >
                  ?
                </span>
                <ModalRequisitos
                  open={requirmentsModalOpen}
                  setOpen={setRequirementsModalOpen}
                />
              </li>

              <li className="aplicaListItem">
                <span className="customBullet">5</span>
                <p>Prepárate para disfrutar de esta gran experiencia.</p>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Element>
      {/* PROXIMOS VIAJES*/}
      {isMobile ? (
        <MobileProximoViajes expeditions={expeditions} />
      ) : (
        <ProximosViajes proximosViajes={proximosViajes} />
      )}
      {/* VIVENCIAS DE VOLUNTARIOS*/}
      {isMobile ? <VivenciaDeVoluntariosMobile /> : <VivenciaDeVoluntarios />}
      {/* INNOVACION SOCIAL */}
      <Element name="consultoriaScreen">
        <Grid
          container
          className="panel"
          id="innovacionBlock"
          direction="row"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="innovacionWrapper">
            <h2 className="sectionTitle expertosInovacion">
              Somos expertos en innovación social
            </h2>
          </div>
          <Innovacion />
        </Grid>
      </Element>
      {/* BLOG */}
      <Grid
        container
        className="panel"
        id="blogBlock"
        direction="column"
        style={{ backgroundColor: "#f9f7f1", minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <h2 className="sectionTitle">Blog</h2>
        </Grid>
        <Blog isMobile={isMobile} posts={posts} />
      </Grid>
      {/* CONTACTO */}
      <Grid
        container
        className="panel"
        id="contactoBlock"
        direction="row"
        style={{ backgroundColor: "#f9f7f1" }}
      >
        <Contacto isMobile={isMobile} />
      </Grid>
    </LandingContainer>
  );
}

export default App;
