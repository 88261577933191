import React from "react";
import { Grid } from "@material-ui/core";

const Causa = props => {
  const { imgSrc, title, subtitle } = props;

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      className="causaContent"
    >
      <img src={imgSrc} className={"causaCardImage"} alt={title}/>
      <div className="causaInnerContent">
      <h2 className="causaCardTitle">{title}</h2>
      <h4 className="causaCardSubtitle">{subtitle}</h4>
      </div>
    </Grid>
  );
};

export default Causa;
