import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  viajarConCausas,
  porqueConCausaCounter,
} from '../../constants/viajarConCausas';
import Causa from '../Causas';
import Counter from '../Counter';

export default function ViajarConCausas() {
  return (
    <Grid container className="panel" id="porqueConCausaBlock" direction="row">
      <h2 className="sectionTitle" id="causasMobileTitle" >¿Por qué viajar con causa?</h2>
      <Grid item xs={12} style={{ margin: 'auto' }}>
        <Grid
          container
          direction="row"
          justify="center"
          spacing={4}
          id="causasContainer"
        >
          {viajarConCausas.map((vol) => (
            <Causa
              imgSrc={vol.imgSrc}
              title={vol.title}
              key={vol.title}
              subtitle={vol.subtitle}
            />
          ))}
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          style={{ width: '95%', margin: 'auto' }}
        >
          {porqueConCausaCounter.map((vol, index) => (
            <Counter
              amount={vol.amount}
              title={vol.title}
              key={vol.subtitle}
              subtitle={vol.subtitle}
              subtitle2={vol.subtitle2 || ''}
              intervalMS={vol.intervalMS}
              smaller={index === 3}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
