import styled from 'styled-components';
import closeIcon from './../../images/iconos/CerrarTurquesa.png';


export const DialogDiv = styled.div`
    width:1120px;
    height: 560px;
    background-color: none;
    display: flex;
    @media (max-width: 760px) {
        max-width: 0;
    }
    @media (min-width: 760px) and (max-width: 1200px) {
        max-width: 90vw;
    }
`

export const InnerCard = styled.div`
    margin-right: 20px;
    margin-top: 20px;
    background-color: #1DCAD3;
    flex:1;
    display:flex;
    flex-direction: row;
    @media (max-width: 760px) {
        flex-direction: column;
    }
`

export const TitleDiv = styled.div`
    width: 50%;
    color: #FFF;
    @media (max-width: 760px) {
        flex: 0;
        width: 100%;
        margin-top: 25px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: "center";
`

export const TitleText = styled.h2`
    font-size: 40pt;
    font-family: 'Work Sans', sans-serif;
    max-width: 350px;
    text-align: left;
    @media (max-width: 760px) {
        font-size: 20pt;
        max-width: none;
        text-align: center;
        padding: 0px 15px;
    }
`

export const RequirementsContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 40px 0;
    @media (max-width: 760px) {
        width: 90%;
        margin: 0;
        padding: 5%;
        flex:1;
    }
`

export const CloseButton = styled.div`
    width: 55px;
    height: 55px;
    position:absolute;
    top:0;
    right:0;
    z-index:10;
    background-image: url(${closeIcon})
`