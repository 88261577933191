import styled from "styled-components";

export const LandingContainer = styled.div`
  font-family: "Work Sans", sans-serif;
  background-color: #f9f7f1;

  .panel {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 80px);
    display: flex;
    margin: 0px;
  }

  .superTexto {
    font-size: 150px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    letter-spacing: 1.2;
    text-align: center;
    color: #ffffff;
    margin: auto;
  }

  .innovacionWrapper {
    width: 50%;
    margin: 0 auto;
  }

  .sectionTitleWrapper {
    width: 70%;
    margin: 0 auto;
  }

  @media (min-width: 300px) and (max-width: 460px) {
    .superTexto {
      font-size: 3.7em;
      margin: auto;
    }

    #secondBlock {
      min-height: 64vh !important;
    }

    .sectionTitle {
      margin-top: 60px !important;
      font-size: 23px !important;
    }

    .innovacionWrapper {
      width: 61%;
      position: relative;
      margin: 0 auto;
    }
  }

  @media (min-width: 460px) and (max-width: 760px) {
    .panel {
      min-height: calc(100vh - 70px);
    }

    .superTexto {
      font-size: 5em;
      margin: auto;
    }

    .aplicaTitle {
      font-size: 3.4em;
      margin: auto;
    }

    #secondBlock {
      min-height: auto !important;
    }

    .firstRow {
      margin-top: 2rem !important;
    }

    .sectionTitle {
      font-size: 37px !important;
    }

    .innovacionWrapper {
      width: 61%;
      position: relative;
      margin: 0 auto;
    }

    .expertosInovacion {
      /*padding: 0 20px !important;*/
    }
  }

  #expandMoreLogo {
    align-self: flex-start;
    bottom: 0;
    color: #ffffff;
    margin: 20px auto 0px;
    width: 140px;
  }

  #expandMore {
    align-self: flex-end;
    bottom: 0;
    color: #ffffff;
    margin: 0px auto;
  }

  .voluntariadosI {
    width: 100%;
    padding: 0px 100px;
  }
  #expandMore img {
    height: 80px;
  }

  #expandMore:hover {
    cursor: pointer;
  }
  /* PRIMERA PANTALLA FIN */
  /* SEGUNDA PANTALLA INICIO */
  #secondBlock {
    min-height: 104vh;
    padding-top: 80px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .secondStepPhrase {
    font-family: "Work Sans", sans-serif;
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.7;
    letter-spacing: normal;
    text-align: center;
    color: #1DCAD3;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 20px;
    z-index: 1;
  }

  .firstRow {
    margin-top: 20vh;
  }
  .lastRow {
    margin-bottom: -4vh;
  }

  @media (min-width: 300px) and (max-width: 760px) {
    #secondBlock {
      padding-top: 0px;
    }
  }

  @media (max-width: 500px) {
    .secondStepPhrase {
      font-size: 14px;
    }
    .lastRow {
      margin-bottom: -1vh;
    }
  }

  @media (min-width: 500px) and (max-width: 760px) {
    .secondStepPhrase {
      font-size: 20px;
    }
  }

  /* SEGUNDA PANTALLA FIN */

  /* VOLUNTARIADOS */
  #voluntariadoBlock {
    background-color: #f9f7f1;
    justify-content: stretch;
  }

  .sectionTitle {
    margin: 20px auto;
    margin-top: 110px;
    width: 100%;
    height: auto;
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #373434;
    font-family: "Work Sans", sans-serif;
    margin-bottom: 110px;
  }

  .carrouselSection {
    width: 70%;
  }

  .control-dots {
    top: 0;
  }

  .control-arrow {
    visibility: hidden;
  }

  .slide {
    background: none !important;
    div {
      p {
        font-weight: bold;
      }
    }
  }

  .sectionSubtitle {
    width: 30%;
    word-wrap: break-word;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.2;
    text-align: center;
    color: #373434;
    margin: auto;
  }

  .cardTitle {
    margin: 15px auto;
    width: 100%;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #373434;
  }

  .cardSubtitle {
    width: 100%;
    word-wrap: break-word;
    /*font-family: WorkSans;*/
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #373434;
    margin: auto;
  }

  .cardImage {
    margin: auto;
    max-width: 80px;
  }
  @media (max-width: 760px) {
    .sectionSubtitle {
      width: 90%;
      font-size: 15px;
    }
  }

  @media (min-width: 1370px) {
    .sectionSubtitle {
      width: 40%;
    }
    .cardSubtitle {
      width: 95%;
    }
    #voluntariadoBlock {
      width: 1366px;
      margin: auto;
    }
  }
  /* VOLUNTARIADOS FIN*/

  /* HUELLAS */
  #huellaBlock {
    background-color: #fff;
    min-height: 80vh;
    padding-top: 0px;
  }

  #huellaImagesContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  #huellaStepper {
    margin: auto;
    width: 100%;
    background-color: transparent;
    justify-content: flex-end;
  }
  #huellaCarousel {
    margin: auto;
    flex: auto;
    background-color: transparent;
    justify-content: flex-end;
    padding-right: 15px;
  }

  svg.MuiSvgIcon-root {
    font-size: 80px;
  }

  div.MuiMobileStepper-dot {
    width: 12px;
    height: 12px;
  }

  div.MuiMobileStepper-dotActive {
    background-color: #1DCAD3;
  }

  .labeledImage {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 30%;

    h2 {
      position: absolute;
      margin: auto;
      color: #ffffff;
      font-weight: bold;
      font-size: 45px;
    }

    .huellaImage {
      width: 100%;
      height: auto;
    }
  }

  .MuiButtonBase-root {
    color: #1DCAD3;
    svg.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
    }
  }

  .MuiMobileStepper-dot {
    display: none;
  }

  @media (max-width: 760px) {
    #huellaStepper {
      margin: auto;
      width: 90vw;
      background-color: transparent;
    }
    #huellaCarousel {
      margin: auto;
      width: 90vw;
      background-color: transparent;
    }

    #huellaImagesContainer {
      flex-direction: row;
    }

    .labeledImage {
      max-width: 50vw;
      margin: auto;

      .huellaImage {
        height: auto;
        width: 40vw;
        margin-top: 20px;
      }

      h2 {
        font-size: 25px;
      }
    }

    #huellaBlock {
      padding-top: 10px;
      background-color: #f9f7f1;
    }

    .huellaBlock {
      background-color: #fff !important;
    }
  }

  /* HUELLAS FIN */

  /* PORQUE CON CAUSA INICIO */
  #porqueConCausaBlock {
    min-height: auto;
    background-color: #f9f7f1;
    margin-top: 100px;
  }

  #causasContainer {
    width: 100%;
    margin: 120px 0px 80px 0px;
  }

  .causaInnerContent {
    position: absolute;
    max-width: 80%;
  }

  .causaCardTitle {
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    word-wrap: break-word;
    margin: auto;
  }

  .causaCardSubtitle {
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    word-wrap: break-word;
  }

  .causaContent {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 50vh;
    max-width: 28%;
    text-align: center;
    margin: 0px 10px;
  }

  .counterCardTitle {
    max-width: inherit;
    font-size: 75px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.7;
    letter-spacing: normal;
    text-align: center;
    color: #1DCAD3;
    margin: 10px 20px;
  }
  .causaCardImage {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
  }

  .counterCardSubtitle {
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1DCAD3;
    max-width: inherit;
    word-wrap: break-word;
    margin: 10px 10px 0px 10px;
  }
  .counterCardSubtitle2 {
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1DCAD3;
    max-width: inherit;
    word-wrap: break-word;
    margin: 0px 10px 0px 10px;
  }

  .counterContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: auto;
    max-width: 400px;
    padding: 10px 0px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (min-width: 770px) and (max-width: 1290px) {
    .counterCardTitle {
      font-size: 60px;
    }

    .counterCardSubtitle {
      font-size: 18px;
    }
    .counterCardSubtitle2 {
      font-size: 18px;
    }

    .causaCardSubtitle {
      font-size: 14px;
    }
  }

  @media (max-width: 769px) {
    #porqueConCausaBlock {
      margin-top: 65px;
    }

    .causaCardTitle {
      font-size: 27px;
      margin-bottom: 40px;
      margin-top: 20px;
    }

    .causaCardImage {
      position: relative;
    }

    #causasMobileTitle {
      font-size: 37px !important;
      margin: 50px 0px 30px 0px !important;
    }

    .causaContent {
      max-width: 100vw;
      height: auto;
      margin: 0px 0px;
      position: relative;
      padding: 0px !important;
      margin-bottom: -15vh;
    }

    .causaInnerContent {
      /*height: 72%; 80*/
      margin-top: -66px;
    }

    .causaContent:nth-child(3) {
      .causaInnerContent {
        /*height: 72%; 80*/
        margin-top: -80px;
      }

      .causaCardTitle {
        margin-bottom: 30px;
        margin-top: 70px;
      }
    }

    .counterCardTitle {
      font-size: 45px; /*26*/
    }

    .counterCardSubtitle {
      font-size: 16px;
    }
    .counterCardSubtitle2 {
      font-size: 16px;
    }

    .causaCardSubtitle {
      font-size: 20px; /*14*/
    }

    #causasContainer {
      margin: 20px 0px 60px 0px;
      overflow: hidden;
    }

    .counterContent {
      max-width: 50vw;
    }
  }

  @media (min-width: 300px) and (max-width: 528px) {
    .counterCardSubtitle,
    .counterCardSubtitle2 {
      font-size: 14px;
    }
    .counterCardTitle {
      font-size: 24px;
    }
  }

  @media (min-width: 350px) and (max-width: 760px) {
    #causasMobileTitle {
      font-size: 23px !important;
      margin-bottom: 0px !important;
    }

    #porqueConCausaBlock {
      margin: 0px !important;
    }

    .causaCardSubtitle {
      font-size: 16px;
      font-weight: 100;
    }
  }

  /* PORQUE CON CAUSA FIN */

  /* BLOG TITULO */
  .blogT {
    margin-bottom: 80px;
    margin-top: 180px;
    width: 100%;
    font-size: 45px;
    font-weight: bold;
    color: #373434;
    text-align: center;
  }

  @media (max-width: 1030px) {
    .blogT {
      margin-top: 80px;
    }
  }
  /* APLICA INICIO */
  #aplicaBlock {
    background-color: #f9f7f1;
    align-items: center;
    justify-content: center;
    min-height: auto;
    margin: 5vh auto;
  }

  .aplicaTitleContainer {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .aplicaTitle {
    max-width: 72%;
    word-wrap: break-word;
    font-size: 72px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: left;
    color: #373434;
  }
  .aplicaListContainer {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .aplicaList {
    list-style-type: none;
    max-width: 80%;
    display: flex;
    flex-direction: column;
  }
  .aplicaListItem {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #373434;
    margin: 15px;
    flex: 1;
    display: flex;
    flex-direction: row;

    p {
      display: inline-block;
      margin: 0px;
      flex: auto;
    }

    span {
      display: inline-block;
      min-width: 30px;
      margin-right: 20px;
    }
  }

  .customBullet {
    width: 30px;
    height: 30px;
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    background-color: #f0b500;
    border-radius: 20px;
    margin-right: 20px;
    cursor: pointer;
  }

  @media (min-width: 770px) and (max-width: 1290px) {
    .aplicaTitle {
      font-size: 65px;
    }
    .aplicaList {
      max-width: 90%;
      margin-bottom: 60px;
    }
  }

  @media (max-width: 770px) {
    .exclamationMark {
      margin-right: 0px !important;
    }
    .aplicaTitle {
      font-size: 27px;
      margin-top: 60px;
      text-align: center;
      width: 240px;
    }
    .aplicaList {
      padding-left: 0px;
      max-width: 77%;
    }

    .aplicaListItem p {
      font-size: 15px;
    }
  }
  /* APLICA FIN */

  /* PROXIMOS VIAJES INICIO */
  #proximosViajesBlock {
    background-color: #1DCAD3;
    justify-content: center;

    .proximoViajeContent {
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 90%;
      position: relative;
      /* min-height: calc(100vh/1.8 + 150px); */

      p {
        margin: 0px;
      }
    }

    .proximoViajeCardImage {
      display: block;
      width: 100%;
      height: auto;
      max-height: 450px;
    }

    .proximoViajeCardImage :hover {
      cursor: pointer;
      margin-bottom: 10px;
    }

    #cardDeslizable {
      width: 100%;
      margin: auto;
      position: relative;
      bottom: 0;
      height: 50%;
      padding-bottom: 20%;
      background-color: #f9f7f1;
    }

    #cardDeslizable :hover {
      cursor: pointer;
    }

    .sectionTitle {
      color: #fff;
      margin-bottom: 0px;
    }

    h2,
    h4,
    a {
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      word-wrap: break-word;
      padding: 20px 20px 0px 20px;
      border: none;
      margin: 0px;
    }

    .proximoViajeCardTitle {
      display: block;
      margin: 15px auto 0px;
      font-size: 24px;
      align-self: flex-end;
    }

    .proximoViajeCardSubtitle {
      display: block;
      /* font-size: 19px; */
      font-size: 14px;
      font-weight: 400;
      padding-top: 0px;
      letter-spacing: 1;
    }
    .proximoViajeDescription {
      display: block;
      font-size: 19px;
      font-weight: 400;
      padding: 0px 20px 0px 20px;
      margin-bottom: 10px;
      flex: auto;
    }
    .detailsTitle {
      display: block;
      font-size: 19px;
      font-weight: 600;
      border: none;
      padding: 10px 20px 0px 20px;
    }
    .proximoViajeLink {
      display: block;
      font-size: 24px;
      font-weight: 400;
      border: none;
      padding: 10px 20px 10px 20px;
      font-style: italic;
      text-decoration: underline;
      position: absolute;
      bottom: 20px;
    }
    .proximoViajeLink:hover {
      cursor: pointer;
    }

    .soldOut {
      background-color: #f0b500;
      border-color: #f0b500;
    }

    @media (max-width: 760px) {
      .proximoViajeContent {
        max-width: 85vw;
        /* height: 30vh; */
      }
    }
    @media (min-width: 1919px) {
      #cardDeslizable {
        height: 25%;
      }
    }
  }

  @media (min-width: 760px) and (max-width: 1280px) {
    .superTexto {
      font-size: 140px;
    }
    #rightSection #imgContainer {
      padding: 24px 40px 24px 20px;
    }
    .panel {
      min-height: calc(95vh - 80px);
    }
    .sectionTitle {
      font-size: 35px;
      margin-top: 80px;
      margin-bottom: 80px;
    }
    .sectionSubtitle {
      font-size: 21px;
    }
    .cardTitle {
      font-size: 35px;
    }

    #causasContainer {
      width: 100%;
      margin: 30px 0px 100px 0px;
    }
    .causaCardTitle {
      font-size: 35px;
    }
    .causaCardSubtitle {
      font-size: 17px;
    }

    .labeledImage {
      font-size: 35px;
    }
    .counterCardTitle {
      font-size: 55px;
    }
    .counterCardSubtitle {
      font-size: 17px;
    }
    .counterCardSubtitle2 {
      font-size: 17px;
    }
    #aplicaBlock {
      margin: 6vh auto;
    }
    .aplicaTitle {
      font-size: 55px;
    }
    .aplicaListItem {
      font-size: 17px;
    }
    .blogT {
      font-size: 35px;
    }
    #masNoticias {
      font-size: 17px;
    }
  }
  @media (min-width: 1300px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 1600px) and (min-width : 1450px){
    #proximosViajesBlock .proximoViajeCardImage {
      max-height: 400px !important;
    }
  }
  @media (max-width: 1449px) and (min-width : 1367px){
    #proximosViajesBlock .proximoViajeCardImage {
      max-height: 350px !important;
    }
  }
  @media (max-width: 1366px) {
    #proximosViajesBlock .proximoViajeCardTitle {
      font-size: 24px;
    }
    #proximosViajesBlock .detailsTitle {
      font-size: 17px;
    }
    #proximosViajesBlock .proximoViajeDescription {
      font-size: 17px;
    }
    #proximosViajesBlock .proximoViajeLink {
      font-size: 19px;
    }
    #proximosViajesBlock .proximoViajeCardImage {
      max-height: 300px !important;
    }
    #proximosViajesBlock #cardDeslizable {
      padding-bottom: 25%;
    }
  }
  @media (max-width: 1100px) and (min-width : 1031px){
    #proximosViajesBlock .proximoViajeCardImage {
      max-height: 290px !important;
      min-height: 290px !important;
    }
    #proximosViajesBlock #cardDeslizable {
      padding-bottom: 30%;
    }
  }
  @media (max-width: 1030px) {
    #proximosViajesBlock .proximoViajeCardTitle {
      font-size: 18px;
    }
    #proximosViajesBlock .proximoViajeCardSubtitle {
      font-size: 13px;
      /* font-size: 16px;   */
    }
    #proximosViajesBlock .detailsTitle {
      font-size: 14px;
    }
    #proximosViajesBlock .proximoViajeDescription {
      font-size: 14px;
    }
    #proximosViajesBlock .proximoViajeLink {
      font-size: 17px;
      padding: 0px 20px;
    }
    #proximosViajesBlock .proximoViajeCardImage {
      max-height: 200px !important;
    }
  }
  @media (max-width: 959px){
    #proximosViajesBlock .proximoViajeCardImage {
      max-height: 400px !important;
    }
  }

  @media (min-width: 1919px) {
    .sectionTitle {
      font-size: 54px;
    }
    #expandMoreLogo {
      width: auto;
    }
    .superTexto {
      font-size: 220px;
    }
    .panel {
      min-height: auto;
    }
    .voluntariadosI {
      width: 100%;
      padding: 0px 0px;
    }
    .secondStepPhrase {
      width: 60vw;
      z-index: 10;
      font-size: 46px;
    }
    .firstRow {
      margin-top: 15vh;
    }
    #secondBlock {
      img {
        width: 100%;
        height: auto;
        bottom: 0;
        z-index: 9;
      }
    }

    .labeledImage h2 {
      font-size: 53px;
    }
    .cardTitle {
      font-size: 54px;
    }
    .cardImage {
      max-width: 110px;
    }
    .sectionSubtitle {
      font-size: 25px;
    }

    .cardSubtitle {
      font-size: 18px;
    }

    #causasContainer {
      margin: 200px 0px 80px 0px;
    }
    .causaContent {
      min-height: 50vh;
      max-width: 28%;
    }

    .causaCardTitle {
      font-size: 54px;
    }
    .causaCardSubtitle {
      font-size: 24px;
    }
    .counterCardTitle {
      font-size: 90px;
    }
    .counterCardSubtitle {
      font-size: 24px;
    }
    .counterCardSubtitle2 {
      font-size: 24px;
    }

    #aplicaBlock {
      margin: 16vh auto !important;
    }

    .aplicaTitle {
      font-size: 92px;
    }
    .aplicaListItem {
      font-size: 31px;
    }

    #proximosViajesBlock .proximoViajeCardTitle {
      font-size: 30px;
    }

    #proximosViajesBlock #cardDeslizable h4 {
      font-size: 24px;
    }
    #proximosViajesBlock .detailsTitle {
      font-size: 24px;
    }
    #proximosViajesBlock .proximoViajeDescription {
      font-size: 24px;
    }
    .innovacionInfoContainer p {
      font-size: 24px;
    }
  }

  /* PROXIMOS VIAJES FIN */
  /* VIVENCIAS INICIO */
  #vivenciasBlock {
    background-color: #fff !important;
    padding-top: 50px;
    position: relative;
    padding-bottom: 50px;

    .sectionTitle {
      margin-bottom: 50px;
    }
    #vivenciasControls {
      margin: auto;
      /* position: absolute;
      width: 90%;
      top: 55%;
      left: 5%;
      background-color: transparent;
      justify-content: space-between; */
      background-color: transparent;
      justify-content: center;
      margin-top: 40px;
    }
  }
  @media (min-width: 1919px) {
    #vivenciasBlock {
      padding-bottom: 100px;
    }
  }
  /* VIVENCIAS FIN */
  /* CONTACTO INICIO */
  @media (min-width: 760px) {
    #contactoBlock {
      min-height: auto;
      height: 53vh;
    }
  }
  /* CONTACTO FIN */
`;
