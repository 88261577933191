import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ButtonNext from '../images/iconos/botonSiguienteActivo.png';
import ButtonBack from '../images/iconos/botonAtrasActivo.png';
import ButtonNextDisabled from '../images/iconos/siguienteDisabled.png';
import ButtonBackDisabled from '../images/iconos/atrasDisabled.png';
import { VivenciasContainer } from './styledVivencias.js';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    justifyContent: 'center',
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
}));

const buttonStyles = makeStyles({
  root: {
      padding: 0,
      marginLeft: "20px",
  },
  label: {
      padding: 0, 
  },
});

export default function HuellaStepper(props) {
  const classes = useStyles();
  const buttonClasses = buttonStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const stepsLength = parseInt(steps.length / 3);
  const maxSteps = steps.length % 3 === 0 ? stepsLength : stepsLength + 1;
  const { vivencias } = props;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    setSteps([...vivencias]);
  }, [vivencias]);

  return (
    <div className={classes.root}>
      <VivenciasContainer>
        {/* MITAD IZQUIERDA */}
        {/* <Grid item xs={12} md={8} xl={6}> /PROPUESTA */}
        <Grid item xs={12} md={8} lg={7}>
          <Grid container id="vivencias2Container">
            {/* ROW SUPERIOR */}
            {steps[activeStep * 3] && (
              <Grid item xs={12} className="vivenciasHorizontalContainer">
                <img
                  className="vivenciasFullHeightImage"
                  src={steps[activeStep * 3].imgSrc}
                  alt={`Uno de los diferentes tipos de voluntariado:${
                    steps[activeStep * 3].title
                  }`}
                />
                <div className="descriptionFullHeightContainer">
                  <h2 className="vivenciasCardTitle">
                    {steps[activeStep * 3].title}
                  </h2>
                  <h4 className="vivenciasCardSubtitle">
                    {steps[activeStep * 3].subtitle}
                  </h4>
                  <h4 className="vivenciasCardDescription">
                    {steps[activeStep * 3].description}
                  </h4>
                </div>
              </Grid>
            )}
            {/* ROW INFERIOR */}
            {steps[activeStep * 3 + 1] && (
              <Grid item xs={12} className="vivenciasHorizontalContainer2">
                <div className="descriptionFullHeightContainer">
                  <h2 className="vivenciasCardTitle">
                    {steps[activeStep * 3 + 1].title}
                  </h2>
                  <h4 className="vivenciasCardSubtitle">
                    {steps[activeStep * 3 + 1].subtitle}
                  </h4>
                  <h4 className="vivenciasCardDescription" id="secondRow">
                    {steps[activeStep * 3 + 1].description}
                  </h4>
                </div>
                <img
                  className="vivenciasFullHeightImage2"
                  src={steps[activeStep * 3 + 1].imgSrc}
                  alt={`Uno de los diferentes tipos de voluntariado:${
                    steps[activeStep * 3 + 1].title
                  }`}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* MITAD DERECHA */}
        {steps[activeStep * 3 + 2] && (
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            className="vivenciasVerticalContainer"
          >
            {/* <Grid item xs={12} md={4} lg={3} xl={2} className="vivenciasVerticalContainer"> PROPUESTA */}
            <img
              className="vivenciasFullWidthImage"
              src={steps[activeStep * 3 + 2].imgSrc}
              alt={`Uno de los diferentes tipos de voluntariado:${
                steps[activeStep * 3 + 2].title
              }`}
            />
            <div className="descriptionFullWidthContainer">
              <h2 className="vivenciasCardTitle">
                {steps[activeStep * 3 + 2].title}
              </h2>
              <h4 className="vivenciasCardSubtitle">
                {steps[activeStep * 3 + 2].subtitle}
              </h4>
              <h4 className="vivenciasCardDescription">
                {steps[activeStep * 3 + 2].description}
              </h4>
            </div>
          </Grid>
        )}
      </VivenciasContainer>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <IconButton
            onClick={handleNext}
            classes={{
                root: buttonClasses.root, // class name, e.g. `classes-nesting-root-x`
                label: buttonClasses.label, // class name, e.g. `classes-nesting-label-x`
            }}
            disabled={activeStep === maxSteps - 1}
          >
            {activeStep === maxSteps - 1 ? (
              <img
                src={ButtonNextDisabled}
                alt="Next Button"
                style={{ height: '50px' }}
              />
            ) : (
              <img
                src={ButtonNext}
                alt="Next Button"
                style={{ height: '50px' }}
              />
            )}
          </IconButton>
        }
        backButton={
          <IconButton 
            onClick={handleBack} 
            classes={{
                root: buttonClasses.root, // class name, e.g. `classes-nesting-root-x`
                label: buttonClasses.label, // class name, e.g. `classes-nesting-label-x`
            }}
            disabled={activeStep === 0}>
            {activeStep === 0 ? (
              <img
                src={ButtonBackDisabled}
                alt="Back Button"
                style={{ height: '50px' }}
              />
            ) : (
              <img
                src={ButtonBack}
                alt="Back Button"
                style={{ height: '50px' }}
              />
            )}
          </IconButton>
        }
        id="vivenciasControls"
      />
    </div>
  );
}
