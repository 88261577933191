import * as firebase from "firebase";

const config = {
  apiKey: "AIzaSyC-awRLa_kkF5eUGmBL80jFL8QO9vpLZFw",
  authDomain: "tierradeartistas-aaf5d.firebaseapp.com",
  projectId: "tierradeartistas-aaf5d"
};

export default !firebase.apps.length
  ? firebase.initializeApp(config)
  : firebase.app();
