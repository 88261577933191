import * as moment from "moment/moment.js";

export function createDateRange(start, end) {
  return `${start && moment(start).locale("es").format("DD")} ${
    end
      ? moment(end).locale("es").format("- DD, MMMM YYYY")
      : moment(start).locale("es").format(", MMMM YYYY")
  }`;
}

export function formatDate(date) {
  return `${date && moment(date).locale("es").format("MMMM DD, YYYY")}`;
}
