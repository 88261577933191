import Image1 from '../../images/tiposVoluntariado/Academico_Icono.png';
import Image2 from '../../images/tiposVoluntariado/Empresarial_Icono.png';
import Image3 from '../../images/tiposVoluntariado/Familiar_Icono.png';
import Image4 from '../../images/tiposVoluntariado/Grupo_Icono.png';
import Image5 from '../../images/tiposVoluntariado/Juvenil_Icono.png';

import Image6 from '../../images/caminoRecorrido/CaminoRecorrido_Foto1.png';
import Image7 from '../../images/caminoRecorrido/CaminoRecorrido_Foto2.png';
import Image8 from '../../images/caminoRecorrido/CaminoRecorrido_Foto3.png';
import Image9 from '../../images/caminoRecorrido/CaminoRecorrido_Foto4.png';

const data = {
    "properties": [
    {
        "_id": "593e9297e17df20c4a237d42",
        "index": 0,
        "picture": Image5,
        "title": "Juvenil",
        "text": "Experiencias sociales alrededor del mundo para almas jóvenes y aventureras. Si tienes entre 18 y 35 años este es el grupo para ti.",
    },
    {
        "_id": "593e9297e17sdf20c4a237d42",
        "index": 1,
        "picture": Image3,
        "title": "Familiar",
        "text": "Vive una experiencia en familia, un viaje lleno de convivencia y valores. Lleva a cabo un proyecto social con una familiad de la comunidad y crea memorias para toda la vida.",
    },
    {
        "_id": "593e9297e17df20c4a237d42",
        "index": 2,
        "picture": Image4,
        "title": "Arma tu grupo",
        "text": "Arma una experiencia de voluntariado personalizada para ti y tu pareja o tu grupo de amigo(as). Todas las edades y nacionalidades son bienvenidas.",
    },
    {
        "_id": "593e9297e17df20c4a237d42",
        "index": 3,
        "picture": Image1,
        "title": "Académico",
        "text": "Realiza un proyecto social con tu equipo académico en conjunto con la comunidad. Hay opciones en diferentes partes de México y puedes recibir horas de servicio social.",
    },
    {
        "_id": "593e9297e17df20c4a237d42",
        "index": 4,
        "picture": Image2,
        "title": "Empresarial",
        "text": "Sé una empresa que hace el bien; se preocupa por sus trabajadores y retribuye a la sociedad. Ejecuta un proyecto comunitario y refuerza los valores de tu empresa.",
    },
    ],
    comoTrabajamos : [
        {
            "open" : Image6,
            "closed" : Image7,
            "title" : "Educativa",
            "text" : "Conferencias, talleres y programas de inmersión comunitaria para generar empatía y sensibilización a través de un acercamiento y convergencia de realidades entre alumnos y comunidades vulnerables",
        },
        {
            "open" : Image8,
            "closed" : Image9,
            "title" : "Empresarial",
            "text" : "Brindamos consultoría en innovación, planeación estratégica y desarrollo social; somos expertos en realizar diagnósticos comunitarios y aplicar la metodología design thinking enfocada en problemáticas sociales.",
        },
    ]
}

export default data;