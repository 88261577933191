import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import ExpandMore from '../../images/iconos/down_arrow.png';
import ImageLogo from '../../images/iconos/logo_tipo.png';
import styled from 'styled-components';
import { useEffect } from 'react';
import { Link } from 'react-scroll';
import Navbar from '../Navbar';

const _LandingBlock = (props) => {
  const { className, isMobile } = props;
  return (
    <Grid
      container
      className={`panel ${className}`}
      direction="column"
      justify="center"
    >
      {!isMobile ? (
        <img
          src={ImageLogo}
          alt="Logo Tierra de artistas"
          id="expandMoreLogo"
        />
      ) : (
        <Navbar
          isMobile={isMobile}
          showCovid={false}
          fillColor={'transparent'}
        />
      )}
      <h1 className="superTexto">{props.blockText}</h1>
      {!isMobile && (
        <Link
          activeClass="active"
          className="test1"
          to="secondScreen"
          spy={true}
          smooth={true}
          duration={500}
          id="expandMore"
        >
          <img src={ExpandMore} alt="Icono para bajar" id="" />
        </Link>
      )}
    </Grid>
  );
};

const StyledLandingBlock = styled(_LandingBlock)`
  height: 100%;
  width: 100%;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  min-height: 100vh !important;
  @media (min-width: 1300px) {
    max-width: 100%;
    margin: auto;
  }
`;

const LandingBlock = ({ isMobile }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    {
      url:
        'https://tierradeartistasimgs.s3.amazonaws.com/images/Slideshow/Imagen_Slideshow-Conoce.png',
      mobileUrl:
        'https://tierradeartistasimgs.s3.amazonaws.com/MobileImages/1.Slideshow/Imagen_Slideshow-Conoce.png',
      text: 'conoce',
    },
    {
      url:
        'https://tierradeartistasimgs.s3.amazonaws.com/images/Slideshow/Imagen_Slideshow-Impulsa.png',
      mobileUrl:
        'https://tierradeartistasimgs.s3.amazonaws.com/MobileImages/1.Slideshow/Imagen_Slideshow-Impulsa.png',
      text: 'impulsa',
    },
    {
      url:
        'https://tierradeartistasimgs.s3.amazonaws.com/images/Slideshow/Imagen_Slideshow-Transforma.png',
      mobileUrl:
        'https://tierradeartistasimgs.s3.amazonaws.com/MobileImages/1.Slideshow/Imagen_Slideshow-Transforma.png',
      text: 'transforma',
    },
  ];

  useEffect(() => {
    const changeImage = () => {
      setCurrentImage(currentImage + 1 < images.length ? currentImage + 1 : 0);
    };

    const intervalRef = setInterval(changeImage, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      clearInterval(intervalRef);
    };
  }, [currentImage, images.length]);

  const typeOfImage = isMobile ? 'mobileUrl' : 'url';

  return (
    <StyledLandingBlock
      imageUrl={images[currentImage][typeOfImage]}
      blockText={images[currentImage].text}
      isMobile={isMobile}
    />
  );
};

export default LandingBlock;
