import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';

export const StyledNavbar = styled.div`
  width: 100vw;
  max-width: 100%;
  height: 80px;
  background-color: ${(props) => (props.isOpen ? "#F9F7F1" : props.fillColor)};
  position: sticky;
  top: ${(props) => (props.notificationMessage ? "26px" : "0px")};
  z-index: 20;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  #burgerMenu {
    color: #fff;
    position: absolute;
    top: 15px;
    left: 1.5em;
  }

  #closeMenu {
    color: "#1DCAD3";
    position: absolute;
    top: 15px;
    right: 1.5em;
  }

  .navLinks {
    background-color: ${(props) =>
      props.isOpen ? "#F9F7F1" : props.fillColor};
    border-bottom: 2px solid transparent;
    text-decoration: none;
    color: ${(props) => (props.isOpen ? props.fillColor : "#F9F7F1")};
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
    margin: 0 auto;
  }

  @media (max-width: 760px) {
    .navLinks {
      border-bottom: 1px solid #1DCAD3;
      border-bottom-width: thin;
      color: ${(props) => (props.isOpen ? " #1DCAD3" : "#F9F7F1")};
    }

    .navLinks:last-child {
      border-bottom: none;
    }
  }

  #opcionesMenuMobile {
    display: flex;
    margin: 0;
    margin-top: 250px;
    width: 100%;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    right: 0;
    top: 0;
    z-index: 204;
    background-color: ${(props) =>
      props.isOpen ? "#F9F7F1" : props.fillColor};
    padding: 25px 0px;
  }

  @media (max-width: 350px) {
    left: -5rem;
  }

  #leftSection {
    width: 33%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding: 20px;

    a {
      color: white;
      text-decoration: none;
      font-weight: 400;
      font-size: 15px;
    }

    span {
      color: white;
      text-decoration: none;
      font-weight: 400;
      font-size: 15px;
    }
  }

  #centerSide {
    flex: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      max-width: 125px;
      margin: auto;
    }
  }

  #rightSection {
    width: 33%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    a {
      color: white;
      text-decoration: none;
      font-weight: 400;
      font-size: 15px;
      text-align: right;
    }

    #imgContainer {
      padding: 19px 40px 19px 20px;
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    img {
      height: 40px;
    }
  }

  @media (max-width: 760px) {
    height: 80px;
    top: 0px;
    #centerSide {
      position: absolute;
      left: 35vw;
      top: 25%;
      width: 30vw;
      font-size: 12px;

      img {
        max-width: 100%;
      }
    }
  }

  @media screen and (max-width: 1366px) {
    height: 70px;
    #leftSection {
      width: calc(33% - 55px);
      margin-right: 1%;
      margin-left: 20px;
    }
    #rightSection {
      #imgContainer {
        padding: 21px 23px 21px 20px;
        img {
          height: 30px;
        }
      }
    }
  }
  @media screen and (min-width: 1920px) {
    #leftSection {
      width: calc(27% - 55px);
      margin-right: 6%;
      margin-left: 20px;
    }
  }
  @media screen and (min-width: 1366px) {
    #centerSide {
      img {
        max-width: 150px;
      }
    }
  }
`;

export const CovidMessage = styled.div`
  width: 100vw;
  max-width: 100%;
  position: sticky;
  top: 0px;
  z-index: 10;
  background-color: #fab700;
  font-size: 18px;
  height: 26px;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0px;
  }

  @media (max-width: 1366px) {
    font-size: 15px;
  }
  @media (max-width: 760px) {
    top: 70px;
    font-size: 12px;
    height: auto;
  }
`;

export const ExpandableNav = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    minWidth: "250px",
    color: "black",
    textDecoration: "none",
    fontWeight: "400",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#1DCAD3",
      color: "white",
    }
  },
  item: {
    backgroundColor: "transparent",
    minWidth: "250px",
    color: "inherit",
    textDecoration: "none",
    fontWeight: "400",
    fontSize: "15px",
    "&:hover": {
    }
  },
}));
