import React, { useState } from "react";
import {NavLink as RouterLink} from  "react-router-dom";
import iconoMiPerfil from "../images/iconos/miPerfilBlanco.png";
import WebLogo from "../images/iconos/logo_tipo.png";
import HeaderLogo from "../images/iconos/logo_header.png";
import IconButton from "@material-ui/core/IconButton";
import Menu from '@material-ui/core/Menu';
import { Grid } from "@material-ui/core";
import { StyledNavbar, CovidMessage, ExpandableNav } from "./styledNavbar";
import { Link } from "react-scroll";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MenuUI from '@material-ui/core/Menu';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    
    '&:hover': {
      backgroundColor: "#1DCAD3",
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const ExpandableOptions = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = ExpandableNav();

  const options = [
    {name : 'CAMINO RECORRIDO' ,  path : "/camino-recorrido"    },
    {name : 'COMO TRABAJAMOS' ,   path : "/como-trabajamos"     },
    {name : 'IMPACTO' ,           path : "/impacto"             },
    {name : 'UN POCO DE NOSOTROS',path : "/un-poco-de-nosotros" },
    {name : 'NUESTRO EQUIPO'     ,path : "/nuestro-equipo"      },
  ];

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
    console.log("OPEN" + anchorEl);

  };

  const handleClose = () => {
    setAnchorEl(null);
    console.log("CLOSE" + anchorEl);

  };

  return <>
    <List component="nav" aria-label="Device settings">
      <ListItem
        button
        aria-controls="customized-menu"
        aria-haspopup="true"
        aria-label="CONOCENOS"
        onClick={handleClickListItem}
      >
        <ListItemText primary="CONÓCENOS" />
      </ListItem>
    </List>
    
    <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <StyledMenuItem
            key={index}  
          >
            <RouterLink
              className={classes.item}
              to={option.path}
              key={option.name}
            >
              <ListItemText primary={option.name} />
            </RouterLink>
          </StyledMenuItem>
        ))}
    </StyledMenu>
  </>
}

const Navbar = ({ isMobile, showCovid, fillColor, isHome, notificationMessage }) => {
  const [isOpen, setOpen] = useState(false);


  return (
    <>
      {notificationMessage && (
        <CovidMessage>
          <p>{notificationMessage}</p>
        </CovidMessage>
      )}
      <StyledNavbar
        isOpen={isOpen}
        notificationMessage={notificationMessage}
        fillColor={fillColor}
      >
        <Grid container direction="row">
          {!isMobile && (
            <div id="leftSection">
              <ExpandableOptions />
              <Link
                activeClass="active"
                className="test1 navLinks"
                to="consultoriaScreen"
                spy={true}
                smooth={true}
                duration={500}
              >
                CONSULTORÍA
              </Link>
            </div>
          )}
          <div id="centerSide">
            <RouterLink
              to={"/"}
            >
              <img src={isOpen ? HeaderLogo : WebLogo} alt="Logo Navbar" />
            </RouterLink>
          </div>
          {isMobile && !isOpen && (
            <IconButton
              className="burgerMenu"
              id="burgerMenu"
              onClick={() => setOpen((wasOpen) => !wasOpen)}
              size="small"
            >
              <StyledMenu fontSize="small" />
            </IconButton>
          )}
          {isOpen && (
            <div id="opcionesMenuMobile">
              <Link
                activeClass="active"
                className="test1 navLinks"
                to="secondScreen"
                spy={true}
                smooth={true}
                duration={500}
              >
                CONÓCENOS
              </Link>
              <Link
                activeClass="active"
                className="test1 navLinks"
                to="consultoriaScreen"
                spy={true}
                smooth={true}
                duration={500}
              >
                VOLUNTARIADO
              </Link>
              <Link
                activeClass="active"
                className="test1 navLinks"
                to="consultoriaScreen"
                spy={true}
                smooth={true}
                duration={500}
              >
                CONSULTORÍA
              </Link>
              <Link
                activeClass="active"
                className="test1 navLinks"
                href="https://bit.ly/aplicatravelteer"
                target="_blank"
                spy={true}
                smooth={true}
                duration={500}
              >
                CONTACTO
              </Link>
              <Link
                activeClass="active"
                className="test1 navLinks"
                to="secondScreen"
                spy={true}
                smooth={true}
                duration={500}
                id="imgContainer"
              >
                MI CUENTA
              </Link>
            </div>
          )}
          {isMobile && isOpen && (
            <IconButton
              className="closeMenu"
              id="closeMenu"
              onClick={() => setOpen((wasOpen) => !wasOpen)}
              size="small"
            >
              <CloseIcon
                fontSize="small"
                style={{ maxWidth: "36px", maxHeight: "36px" }}
              />
            </IconButton>
          )}
          {!isMobile && (
            <div id="rightSection">
              <a
                className="test1 navLinks"
                href="https://bit.ly/aplicatravelteer"
                target="_blank"
              >
                APLICA
              </a>
              <Link
                activeClass="active"
                className="test1 navLinks"
                to="secondScreen"
                spy={true}
                smooth={true}
                duration={500}
                id="imgContainer"
              >
                <img src={iconoMiPerfil} alt="mi cuenta" />
              </Link>
            </div>
          )}
        </Grid>
      </StyledNavbar>
    </>
  );
};

export default Navbar;
