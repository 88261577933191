import React from 'react';
import './ImageCardSelection.css';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Grid from "@material-ui/core/Grid";
import { useState } from 'react';

import ButtonNext from '../../images/iconos/botonSiguienteActivo.png';
import ButtonBack from '../../images/iconos/botonAtrasActivo.png';
import ButtonNextDisabled from '../../images/iconos/siguienteDisabled.png';
import ButtonBackDisabled from '../../images/iconos/atrasDisabled.png';

const useStyles = makeStyles({
    root: {
        backgroundColor: "transparent",
        borderStyle: "none",
        boxShadow: "none",
        marginBottom: "10%",
        maxHeight: "25%",
        minHeight: "25%",
        height: "25%",
    },
    media: {
        height: "140",
        backgroundColor: "transparent",
        borderStyle: "none",
    },
    card: {
    },
});

const buttonStyles = makeStyles({
    root: {
        padding: 0,
        marginLeft: "20px",
    },
    label: {
        padding: 0, 
    },
});

// class component
function ImageCardSelection (props) {
    const {properties} = props;
    
    //---------------------------------------------------------------------------
    // useState Hook
    //---------------------------------------------------------------------------
    const [currentIndex, setCurrentIndex] = useState(0);
    const [disableNext, setDisableNext] = useState(false);
    const [disablePrev, setDisablePrev] = useState(true);
    const classes = useStyles();
    const buttonClasses = buttonStyles();
    
    //---------------------------------------------------------------------------
    // functions
    //---------------------------------------------------------------------------
    const nextImage = () => {
        let newIndex = currentIndex + 1;
        setDisabledButtons(newIndex);        
        setCurrentIndex(newIndex);
    }

    const prevImage = () => {
        let newIndex = currentIndex - 1;
        setDisabledButtons(newIndex);
        setCurrentIndex(newIndex);
    }

    const setDisabledButtons = (newIndex) => {
        (newIndex >= properties.length - 1) ? setDisableNext(true) : setDisableNext(false);
        (newIndex <= 0) ? setDisablePrev(true) : setDisablePrev(false);
    }

        return (
        <div>
            <Grid
                className={classes.root} 
                container
                justify="center"
                direction="column"
                alignItems="center"
                alignContent="center"
                >
                <Grid item>
                    <div className="Card">
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            className={classes.card}
                        >
                            <Grid item>
                                <div className="ImagePartner"></div>
                                <div className="ImageContainer">
                                    <img className="CardImage" 
                                        src={properties ? properties[currentIndex].picture : ""} 
                                        alt={properties ? properties[currentIndex].title : ""}/>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className="Content">
                                    <div className="TitulosExtras">
                                        Tipo de voluntariado:
                                    </div>
                                    <div className ="Informacion">
                                        {properties ? properties[currentIndex].title : ""}
                                    </div>
                                    <div className = "TextoCorrido">
                                        {properties ? properties[currentIndex].text : ""}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item >
                    <IconButton aria-label="back" 
                        onClick={prevImage}
                        classes={{
                            root: buttonClasses.root, // class name, e.g. `classes-nesting-root-x`
                            label: buttonClasses.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        disabled={disablePrev}> 
                        <img
                            src={ disablePrev ? ButtonBackDisabled : ButtonBack}
                            alt="Back Button"
                            className="IconArrowButton"
                        />
                    </IconButton>
                    <IconButton aria-label="back" 
                        onClick={nextImage}
                        classes={{
                            root: buttonClasses.root, // class name, e.g. `classes-nesting-root-x`
                            label: buttonClasses.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        disabled={disableNext}> 
                        <img
                            src={disableNext ? ButtonNextDisabled: ButtonNext}
                            alt="Back Button"
                            className="IconArrowButton"
                        />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
        );
}

export default ImageCardSelection;
