
import React, { useState } from "react";
import TitleField from '../../components/TitleField/TitleField';
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useStyles} from './UnPocoDeNosotrosStyles';
import {nuestrosValores, NosotrosBanner, NosotrosBottom, MisionVision, PasosNosotros, cosasQueNosDistinguenData} from './data';
import NavBar from "../../components/Navbar.js";
import Contacto from "../../components/Contacto.js";
import './UnPocoDeNosotros.css';
import './../../AppStyles.css';
import styled from 'styled-components';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const StyledLandingBlock = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  min-height: 90vh !important;
  @media (min-width: 1300px) {
    max-width: 100%;
    margin: auto;
  }
`;

const ImageTopWithTextBottom = (props) => {
    let itemOrientation = <div className="horizontalContainer">
        <div className="spaceInLeft">
        </div>
        <div className="textInRight">
            <img src={props.image} alt="hozImg" className="imgLeft"/>
            <div className="Informacion">{props.text}</div>
        </div>
    </div>;

    if(props.orientation === "left"){
        itemOrientation = <div className="horizontalContainer">
            <div className="textInLeft marginLeft">
                <div className="imageToRight">
                    <img src={props.image} alt="hozImg" className="imgRight floatRight"/>
                </div>
                <div className="Informacion textRight">{props.text}</div>
            </div>
            <div className="spaceInRight">
            </div>
        </div>;
    }
    return itemOrientation;
};

const ImageWithText = (props) => {
    let itemOrientation = <div className="horizontalContainer">
        <div className="imageInLeft">
            <img src={props.image} alt="hozImg" className="imgLeft"/>
        </div>
        <div className="textInRight">
            <div className="putToMiddleText">
                <div className="Titulos SecondaryColor StandardBoldText">{props.title}</div>
                <div className="Informacion">{props.text}</div>
            </div>
        </div>
    </div>;

    if(props.orientation === "left"){
        itemOrientation = <div className="horizontalContainer">
            <div className="textInLeft">
                <div className="putToMiddleText">
                    <div className="Titulos SecondaryColor StandardBoldText">{props.title}</div>
                    <div className="Informacion">{props.text}</div>
                </div>
            </div>
            <div className="imageInRight">
                <img src={props.image} alt="hozImg" className="imgRight "/>
            </div>
        </div>;
    }
    return itemOrientation;
};

const IconWithText = (props) => {
    return <div className="centerElement iconWithText">
        <table className="imageTop">
            <tbody><tr>
                <td><img src={props.icon} alt="hozImg"/></td>
            </tr></tbody>
        </table>
        <div className="titleMiddle Informacion StandardBoldText SecondaryColor">{props.title}</div>
        <div className="textBottom Informacion">{props.text}</div>
    </div>
}

const UnPocoDeNosotrosScreen = (props) => {
    //---------------------------------------------------------------------------
    // Text Constants
    //---------------------------------------------------------------------------
    const TitleText = (<p> Así es como disminuimos <br/> la pobreza, la desigualdad <br/> y creamos empleos dignos </p>);
    const BottomTitle = (<p> 7 cosas que nos distinguen </p>);
    const isMobile = useMediaQuery("(max-width:760px)");

    //---------------------------------------------------------------------------
    // useState Hook
    //---------------------------------------------------------------------------
    const [selectedPasoIndex, setSelectedPasoIndex] = useState(0);

    return <div className="UnPocoDeNosotros">
                <NavBar isMobile={isMobile} showCovid={true} fillColor={"#1DCAD3"} isHome={true} />
                
                <StyledLandingBlock imageUrl={NosotrosBanner}>
                    <div className="bannerTitle">
                        <TitleField text={TitleText} color="alternative"/> 
                    </div>
                    <div className="whiteContainerTop">
                        { PasosNosotros.map(element => {
                            return <ImageTopWithTextBottom  
                                    key= {element["_id"]}
                                    text = {element["text"]}
                                    orientation = {element["orientation"]}
                                    image = {element["picture"]} />
                        })}
                    </div>
                </StyledLandingBlock>

                <div className="emptyCompensator StandardSpacingText"></div>
                <div className="horizontalContainer"></div>
                
                <div className="centerElement StandardSpacingText">
                    <p className="Titulos SecondaryColor StandardBoldText">Nuestros Valores</p>
                </div>
 
                <Grid
                container
                justify="center"
                alignContent="center"
                >
                    {nuestrosValores.map(element => {
                        return <Grid 
                        key={element.id}
                        item 
                        xs={12}
                        sm={3}>
                            <IconWithText 
                                icon = {element.icon}
                                title = {element.title}
                                text = {element.text}
                            />
                        </Grid>
                    })}
                </Grid>
                <div className="horizontalContainer"></div>
                <div className="horizontalContainer"></div>

                {MisionVision.map(element => {
                    return <ImageWithText 
                    key= {element["_id"]}
                    title = {element["title"]}
                    text = {element["text"]}
                    orientation = {element["orientation"]}
                    image = {element["picture"]} 
                    />
                })}

                <div className="horizontalContainer"></div>

                <div className="container almostFullView">
                    <img src={NosotrosBottom} alt="BannerImpacto" className="customViewBottomImage"/>
                    <div className="bottomTitle">
                        <TitleField text={BottomTitle} color="alternative"/> 
                    </div>
                    <div className="sieteCosasContainer">
                        <Grid
                        container
                        justify="center"
                        alignContent="center"
                        spacing={1}
                        >
                            {cosasQueNosDistinguenData.map(element => {
                                return <div className=" iconSieteCosasContainer" key={element.id}>
                                        <table className="iconSieteCosas imageTop">
                                            <tbody><tr>
                                                <td><img 
                                                        src={element.icon} 
                                                        alt="hozImg" 
                                                        className="imageSieteCosas"
                                                        onClick={()=>setSelectedPasoIndex(element?.index || 0)}
                                                /></td>
                                            </tr></tbody>
                                        </table>
                                        <div className="containerArrow">
                                            <div className="centeredArrow">
                                                { parseInt(cosasQueNosDistinguenData[selectedPasoIndex]?.index ) === parseInt(element.index)  && <ExpandMoreIcon style={{fill: "#1DCAD3"}} className="svg_icons"/> }
                                            </div>
                                        </div>
                                    </div>
                            })}
                        </Grid>
                        <div className="centerElement textSectionSieteCosas">
                            <div className="Informacion AlternativeColor StandardHalfSpacingText StandardBoldText">{cosasQueNosDistinguenData[selectedPasoIndex]?.title || ""}</div>
                            <div className="Informacion AlternativeColor sieteCosasText">{cosasQueNosDistinguenData[selectedPasoIndex]?.text || ""}</div>
                        </div>
                    </div>
                </div>

                <Grid
                className={useStyles.root} 
                container
                justify="center"
                >
                    <Grid 
                        item xs={12}
                        container
                        id="contactoBlock"
                        className="panel">
                        <Contacto isMobile={isMobile}/>
                    </Grid>
                </Grid>
            </div>;
};

export default UnPocoDeNosotrosScreen;
