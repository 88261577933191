import React, { Component } from 'react';
import './TitleField.css';
import '../../AppStyles.css';

// class component
class TextField extends Component {


  render() {

    let titleClasses = ["Page-Title", "Titulos"];
    let numerationClasses = ["Numeration", "Titulos"];

    if(this.props?.color === "secondary"){
      titleClasses.push("SecondaryThemeColor");
      numerationClasses.push("SecondaryThemeColor");
    }
    else if(this.props?.color === "alternative"){
      titleClasses.push("AlternativeThemeColor");
      numerationClasses.push("AlternativeThemeColor");
    }
    else{
      titleClasses.push("ThemeColor");
      numerationClasses.push("ThemeColor");
    }

    return(
      <div>
        <h1 className={titleClasses.join(" ")}>
          {
            this.props?.numeration &&
            <p className="NumeroTitulo Numeration">
              {this.props.numeration}
            </p>  
          }
          {this.props.text}
        </h1>
      </div>
    );
  }
}

export default TextField;
