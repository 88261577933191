import {Route, BrowserRouter as Router, Switch} from "react-router-dom";
import React from 'react';
import Home from './Screens/Home/Home.js';
import CaminoRecorrido from './Screens/CaminoRecorrido/CaminoRecorrido';
import ComoTrabajamos from './Screens/ComoTrabajamos/ComoTrabajamos';
import Impacto from './Screens/Impacto/Impacto';
import UnPocoDeNosotros from './Screens/UnPocoDeNosotros/UnPocoDeNosotros';
import NuestroEquipo from './Screens/NuestroEquipo/NuestroEquipo';

const App = props =>{

    return <div>
        <Router>
            <Switch>
                <Route path="/camino-recorrido" component={CaminoRecorrido} />
                <Route path="/como-trabajamos" component={ComoTrabajamos} />
                <Route path="/impacto" component={Impacto} />
                <Route path="/un-poco-de-nosotros" component={UnPocoDeNosotros} />
                <Route path="/nuestro-equipo" component={NuestroEquipo} />
                <Route path="/" component={Home} />
            </Switch>
        </Router>
    </div>
}

export default App;