import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { ContactoContainer } from './styledContacto.js';
import IGicon from '../images/iconos/IG.png';
import FBicon from '../images/iconos/FB.png';
import TWicon from '../images/iconos/TW.png';
import YTicon from '../images/iconos/Youtube.png';
import SPicon from '../images/iconos/Spotify.png';
import MAILicon from '../images/iconos/MAIL.png';
import LogoFooter from '../images/iconos/logo_Footer.png';
import axios from 'axios';

const Contacto = (props) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('email', email);
    data.append('name', name);

    axios({
      url: 'http://tierradeartistas.org/send-mail.php',
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: data,
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        setEmail('');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Grid item xs={12} style={{ width: '100%', height: '100%' }}>
      <ContactoContainer>
        <div className="contactoIzquierda">
          <div className="contactoRow">
            <img id="logo" src={LogoFooter} alt="Logo Footer" />
          </div>

          <div className="contactoRow linkContainer">
            <p 
              className="links grid1"
              onClick={(e) => {
                  e.preventDefault();
                  window.location.href='mailto:dabella@tierradeartistas.org';
                }}
                >Contacto</p>
            <p className="links grid2">Blog</p>
            <p className="links grid3">FAQ</p>
            <p className="links grid4">Aviso de Privacidad</p>
            {props.isMobile ? null : <p className="links"></p>}
            <p className="links grid5">Términos y condiciones</p>
          </div>

          <div className="contactoRow copyright">
            <p id="copyright">
              ©2020 Tierra de Artistas. Todos los derechos reservados Sitio web
              diseñado por
            </p>
          </div>
        </div>

        <div className="contactoDerecha">
          <div className="contactoRow2">
            <h2 id="encabezado">Sé el primero en enterarte</h2>
          </div>
          <div className="contactoRow2">
            <h2 id="frase">
              Déjanos tu nombre y mail, y entérate de promociones, nuevos viajes, y otras
              sorpresas.
            </h2>
          </div>
          <div className="contactoRow2">
            <form
              id="contact-form"
              className="inputContainer"
              name="contact-form"
              onSubmit={handleSubmit}
              style={{marginBottom: "0"}}
            >
              <input
                placeholder="name"
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </form>
          </div>
          <div className="contactoRow2">
            <form
              id="contact-form"
              className="inputContainer"
              name="contact-form"
              onSubmit={handleSubmit}
              style={{marginTop: "2px", height: "40px"}}
            >
              <input
                placeholder="email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button id="inputButton" type="submit">
                <img src={MAILicon} alt="mail icon" id="inputImage" />
              </button>
            </form>
          </div>
          <div className="contactoRow2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/tierradeartistas"
            >
              <img src={FBicon} alt="facebook icon" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/tierradeartistas/"
            >
              <img src={IGicon} alt="instagram icon" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/tierra_artistas"
            >
              <img src={TWicon} alt="twitter icon" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCVWPpgckyNRA7vzIWSsGotg"
            >
              <img src={YTicon} alt="youtube icon" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://open.spotify.com/show/1Dujmmw1bFpBaOodk9MiiK?si=Xc-BzGpWTiuELpR2p9oBuA"
            >
              <img src={SPicon} alt="spotify icon" />
            </a>
          </div>
        </div>
      </ContactoContainer>
    </Grid>
  );
};
export default Contacto;
